import React, { useEffect, useRef, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { format as formatDate } from 'date-fns';
import * as colors from '../../../colors';
import { getFormattedNumber } from '../../../utility-functions';
import { MINUTES_PER_YEAR, MINUTES_PER_DAY, MINUTES_PER_WEEK } from '../../../constants';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';

const MILLISECONDS_PER_SECOND = 1000;
const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * 60;

const viewModeStyles = props => ({
	popout: {
		margin: {
			right: 300,
			top: 20,
			bottom: 65,
			left: props.showYAxis ? 30 : 25,
		},
		enableSlices: props.data.length <= 15 ? 'x' : false,
	},
	overview: {
		margin: {
			right: Math.max(props.widthInPx / 4, 150),
			top: 20,
			bottom: 65,
			left: props.showYAxis ? 40 : 25,
		},
		enableSlices: props.data.length <= 3 ? 'x' : false,
	},
	report: {
		margin: {
			right: Math.max(props.widthInPx / 4, 150),
			top: 10,
			bottom: props.isHourFormat ? 48 : 65,
			left: props.showYAxis ? 40 : 25,
		},
	},
});
//Linechart component
function shouldXAxisDisplayHourFormat(data) {
	let min = undefined;
	let max = undefined;
	data.forEach(value => {
		const localMin = value.data[0]?.x;
		const localMax = value.data[value.data.length - 1]?.x;
		if (localMin < min || min === undefined) {
			min = localMin;
		}
		if (localMax > max || max === undefined) {
			max = localMax;
		}
	});
	return (max - min) / MILLISECONDS_PER_MINUTE <= MINUTES_PER_DAY;
}

const APPROX_PX_PER_WIDTH = 92;

function LineChart({ data, normalized, yLegend, viewMode, width, animation = true, dataLoadedCallback, interval, dataType }) {
	const { t } = useTranslation();
	const widthRef = useRef();
	const numUnits = new Set(data.reduce((agr, { unit }) => (unit != null ? [...agr, unit] : agr), [])).size;
	const showYAxis = numUnits < 2 || normalized;
	const isHourFormat = shouldXAxisDisplayHourFormat(data);
	const dataIsEmpty = data.reduce((acc, curr) => (acc += curr.data.length), 0) === 0;

	const getXAxisFormat = value => {
		if (isHourFormat) {
			return formatDate(new Date(value), 'HH:mm');
		}
		return formatDate(new Date(value), 'yyyy-MM-dd');
	};

	const getToolTipFormat = value => {
		const date = new Date(value);

		if (dataType && dataType === 'raw') return formatDate(date, 'yyyy-MM-dd | HH:mm');
		if (interval === undefined) return formatDate(date, 'yyyy MM-dd');

		switch (interval.type) {
			case 'hour':
				return formatDate(date, 'yyyy-MM-dd | HH:mm');
			case 'month':
				return formatDate(date, 'yyyy | ') + date.toLocaleString(i18n.language, { month: 'long' });
			case 'year':
				return formatDate(date, 'yyyy');
			default:
				return formatDate(date, 'yyyy MM-dd');
		}
	};

	useEffect(() => {
		if (dataLoadedCallback !== undefined) {
			dataLoadedCallback();
		}
	}, []);

	const widthInPx = widthRef.current?.offsetWidth || APPROX_PX_PER_WIDTH * width;
	return (
		<>
			<div ref={widthRef} className='main-div' style={{ width: '100%', height: '100%', marginTop: '-15px' }}>
				{dataIsEmpty ? (
					<MuiAlert style={{ marginTop: '20px' }} elevation={2} severity='warning'>
						{t('charts.noSensorData')}
					</MuiAlert>
				) : (
					<ResponsiveLine
						data={data}
						animate={animation}
						xScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
						yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
						yFormat=' >-.2f'
						curve='linear'
						axisTop={null}
						axisRight={null}
						colors={[
							colors.secondaryC,
							colors.secondaryF,
							colors.secondaryA,
							colors.secondaryB,
							colors.secondaryE,
							colors.secondaryG,
						]}
						axisBottom={{
							orient: 'bottom',
							tickSize: 5,
							tickPadding: 5,
							legend: '',
							tickRotation: 40,
							legendOffset: -40,
							legendPosition: 'middle',
							format: value => getXAxisFormat(value),
						}}
						axisLeft={
							showYAxis
								? {
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: normalized ? '' : yLegend,
										legendOffset: -40,
										legendPosition: 'middle',
										format: val => getFormattedNumber(val),
									}
								: null
						}
						enableArea={false}
						areaOpacity={0.35}
						useMesh={true}
						lineWidth={2}
						enablePoints={false}
						enableCrosshair={true}
						// enableSlices={(popout && data.length <= 15) || data.length <= 3 ? 'x' : false}
						crosshairType='bottom'
						sliceTooltip={({ slice }) => {
							const points = slice.points;
							return NonOverflowTooltipWrapper({
								point: points[0],
								innerComponent: (
									<>
										{points.map((point, idx) => (
											<div key={idx.toString()}>
												{point?.serieId +
													': ' +
													getFormattedNumber(point?.data?.yLabel) +
													' ' +
													(point.data.unit || '')}{' '}
												<br />
												<div
													style={{
														width: '100%',
														height: '0.3rem',
														background: point.serieColor,
														margin: '0.2rem 0 0.25rem 0',
													}}
												/>
											</div>
										))}

										<div style={{ color: '#444' }}>{getToolTipFormat(points[0].data.x)}</div>
									</>
								),
							});
						}}
						tooltip={({ point }, ...other) => {
							return NonOverflowTooltipWrapper({
								point: point,
								innerComponent: (
									<>
										{point?.serieId + ': ' + getFormattedNumber(point?.data?.yLabel) + ' ' + (point.data.unit || '')}{' '}
										<br />
										<div style={{ color: '#444' }}>{getToolTipFormat(point.data.x)}</div>
										{
											<div
												style={{
													width: '100%',
													height: '0.3rem',
													background: point.serieColor,
													margin: '0.2rem 0 0.25rem 0',
												}}
											/>
										}
									</>
								),
							});
						}}
						theme={{
							legends: {
								text: {
									fontSize: 11,
								},
							},
						}}
						legends={[
							{
								anchor: 'bottom-right',
								direction: 'column',
								justify: false,
								translateX: 90,
								translateY: data.length < 11 ? 0 : 60,
								itemsSpacing: 0,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 20,
								itemOpacity: 0.75,
								symbolSize: 7,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
								effects: [
									{
										on: 'hover',
										style: {
											itemBackground: 'rgba(0, 0, 0, .03)',
											itemOpacity: 1,
										},
									},
								],
							},
						]}
						{...viewModeStyles({ widthInPx, isHourFormat, showYAxis, data })[viewMode]}
					/>
				)}
			</div>
		</>
	);
}

const NonOverflowTooltipWrapper = props => {
	const [tooltipSize, setTooltipSize] = React.useState({ width: 0, height: 0 });
	// dynamically get the size of the tooltip
	React.useEffect(() => {
		const tooltip = document.querySelector('.nivo_tooltip');
		if (tooltip) {
			const { width, height } = tooltip.getBoundingClientRect();
			setTooltipSize({ width, height });
		}
	}, [setTooltipSize]);

	// only show it to the right of the pointer when we are close to the left edge
	const translateX = React.useMemo(
		() => (props.point.x < (tooltipSize.width * 1.3) / 2 ? 0 : -tooltipSize.width),
		[tooltipSize, props.point.x]
	);

	// only show it below the pointer when we are close to the top edge
	const translateY = React.useMemo(
		() => (props.point.y < (tooltipSize.height * 1.3) / 2 ? 0 : -tooltipSize.height),
		[tooltipSize, props.point.y]
	);

	return (
		<div
			className={'nivo_tooltip'}
			style={{
				position: 'absolute',
				transform: `translate(${translateX}px, ${translateY}px)`,
				background: '#ffffff',
				padding: '12px 16px',
				width: 'fit-content',
				textWrap: 'nowrap',
				whiteSpace: 'nowrap',
			}}
		>
			<div style={{ position: 'relative' }}>{props.innerComponent}</div>
		</div>
	);
};

export default LineChart;
