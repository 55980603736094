import React, { useEffect } from 'react';
import { Card, Tab, Tabs, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { PAGES } from '../constants';
import * as colors from '../colors';
import { connect } from 'react-redux';
import { setPage } from '../redux/actionCreators';

function Analysis(props) {
	const headerStyle = { fontSize: '130%', fontWeight: '300', color: '#222', margin: '1rem 0 1rem 2rem' };
	const imgStyle = { width: 'calc(100% - 2rem)', height: '17rem', display: 'block', margin: '1rem' };
	const tabsStyle = makeStyles({
		root: {
			backgroundColor: '#fff',
			width: '60rem',
		},
		indicator: {
			backgroundColor: colors.bgcolor,
			height: '0.06rem',
			margin: '0 0 0.3rem 0',
		},
	})();
	const tabStyle = makeStyles({
		root: {
			color: colors.text,
			fontWeight: 900,
			minWidth: '8rem',
		},
		selected: {
			color: colors.accent1,
		},
	})();

	const { t } = useTranslation();

	useEffect(() => {
		props.setPage(PAGES.analysis.id);
	}, []);

	return (
		<div>
			<Card style={{ width: '77rem', height: '50rem' }}>
				<h2 style={{ fontSize: '160%', fontWeight: '300', color: '#222', margin: '1.2rem 0 0.5rem 2.2rem' }}>Analys</h2>

				<Tabs value={0} classes={tabsStyle} style={{ margin: '0.5rem 0 0 1.5rem' }}>
					<Tab label={t('analysis.optimization')} classes={tabStyle} />
					<Tab label={t('analysis.prediction')} classes={tabStyle} />
					<Tab label={t('analysis.machineLearning')} classes={tabStyle} />
					<Tab label={t('analysis.deepAnalysis')} classes={tabStyle} />
					<Tab label={t('analysis.regressionAnalysis')} classes={tabStyle} />
				</Tabs>

				<div style={{ display: 'grid', gridTemplateColumns: '50% auto', gridTemplateRows: '50% auto' }}>
					<div style={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>
						<div style={headerStyle}>{t('analysis.temperature')}</div>
						<img
							src={require('../assets/dummy-pictures/dot-chart.png').default}
							alt={t('analysis.temperatureTrend')}
							style={imgStyle}
						/>
					</div>
					<div style={{ gridColumn: '2 / 3', gridRow: '1 / 2' }}>
						<div style={headerStyle}>{t('analysis.energy')}</div>
						<img src={require('../assets/dummy-pictures/line-chart.png').default} alt={t('analysis.energy')} style={imgStyle} />
					</div>
					<div style={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}>
						<div style={headerStyle}>{t('analysis.topConsumer')}</div>
						<img
							src={require('../assets/dummy-pictures/building-diagram.png').default}
							alt={t('analysis.topConsumer')}
							style={imgStyle}
						/>
					</div>
					<div style={{ gridColumn: '2 / 3', gridRow: '2 / 3' }}>
						<div style={headerStyle}>{t('analysis.latest24h')}</div>
						<img
							src={require('../assets/dummy-pictures/accumulated-data.png').default}
							alt={t('analysis.latest24h')}
							style={imgStyle}
						/>
					</div>
				</div>
			</Card>

			<Dialog open={true}>
				<DialogTitle>{t('analysis.siteUnavailable')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('analysis.siteNotInSubscription')}</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}

const AnalysisHoc = connect(null, { setPage })(Analysis);
export { AnalysisHoc as Analysis };
