import React, { useState, useRef } from 'react';
import { TextField, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import * as colors from '../colors';

/**
 * Text that can be edited and saved
 * @param {text} text : Unedited text to display
 * @param {(string) => void} onSave : Called with the new text when it is to be saved
 */
function EditableText(props) {
	const [isEditing, setIsEditing] = useState(false);
	const inputRef = useRef();
	const { t } = useTranslation();

	return isEditing ? (
		<>
			<TextField
				inputRef={inputRef}
				multiline
				variant='outlined'
				margin='dense'
				fullWidth
				rows={2}
				rowsMax={15}
				autoFocus
				inputProps={{ style: { fontSize: '86%' } }}
				style={{ margin: '0' }}
				defaultValue={props.text}
			/>

			<div style={{ margin: '0.2rem 0 0 auto' }}>
				<Button
					onClick={() => setIsEditing(false)}
					variant='outlined'
					size='small'
					style={{ marginRight: '0.2rem', color: '#0007' }}
				>
					{t('generic.cancel')}
				</Button>
				<Button
					onClick={() => {
						setIsEditing(false);
						// eslint-disable-next-line no-unused-expressions
						props.onSave?.(inputRef.current.value);
					}}
					variant='outlined'
					size='small'
					style={{color: colors.text}}
				>
					{t('generic.save')}
				</Button>
			</div>
		</>
	) : (
		<div onClick={() => setIsEditing(true)} style={{ whiteSpace: 'break-spaces', cursor: 'pointer' }}>
			{props.text || '-'}
		</div>
	);
}

export default EditableText;
