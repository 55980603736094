import React from 'react';
import MaterialTable from '@material-table/core';
import { MTableToolbar } from '@material-table/core';
import {
	AddBox,
	ArrowUpward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
} from '@material-ui/icons';
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import * as colors from '../colors';
import { drawTruncatedStr } from '../utility-functions';

const materialTableIcons = {
	Add: AddBox,
	Check: Check,
	Clear: Clear,
	Delete: DeleteOutline,
	DetailPanel: ChevronRight,
	Edit: Edit,
	Export: SaveAlt,
	Filter: FilterList,
	FirstPage: FirstPage,
	LastPage: LastPage,
	NextPage: ChevronRight,
	PreviousPage: ChevronLeft,
	ResetSearch: Clear,
	Search: Search,
	SortArrow: ArrowUpward,
	ThirdStateCheck: Remove,
	ViewColumn: ViewColumn,
};

const DEFAULTS = Object.freeze({ headerColor: colors.text, headerSelectColor: colors.accent1 });

/**
 * Allows creation of boilerplate-free Material-tables
 * props.columns has custom fields {maxLength: Column is truncated if longer than this, dontTruncate: Disables truncation, truncateLeft: Truncate from the left instead of the right side}
 * @param {Object} props : Passed on to the actual MaterialTable
 * @param {String} headerSelectColor : Used when hovering and sorting header columns
 * @param {Number} maxColumnLength : How many characters a column string may at most contain before it is truncated (can be overridden by props.columns.maxLength)
 * @param {Boolean} reducePadding : More invasive measures will be used for reducing the table width
 * @param {CSS units} toolbarHeight : Overrides default toolbar-height
 */
function MaterialTableWrapper(props) {
	const { t } = useTranslation(); //TODO: Is this a good way to instance the translation in this context?
	return (
		<MuiThemeProvider
			theme={createMuiTheme({
				palette: {
					secondary: {
						main: colors.primary,
					},
				},
				overrides: {
					MuiTableSortLabel: {
						root: {
							color: (((props.options || {}).headerStyle || {}).color || DEFAULTS.headerColor) + ' !important',
							'&:hover': {
								color: props.headerSelectColor || DEFAULTS.headerSelectColor,
							},
						},
						active: {
							color: (props.headerSelectColor || DEFAULTS.headerSelectColor) + ' !important',
						},
						icon: {
							color: (props.headerSelectColor || DEFAULTS.headerSelectColor) + ' !important',
							position: 'absolute', // Makes header-fields narrower
							right: '-24px', // -||-
							[props.reducePadding ? 'marginRight' : '']: '0',
						},
					},
					PrivateSwitchBase: {
						root: {
							color: `${colors.text} !important`,
						},
						disabled: {
							color: `${colors.disabledBg} !important`,
						},
					},
				},
			})}
		>
			<MaterialTable
				{...props}
				title={
					<h1
						style={{
							fontSize: `${1.25 - 0.032 * Math.max(0, props.title?.length - 41 || 0)}rem`,
							fontWeight: '500',
							whiteSpace: 'nowrap',
							margin: 'auto 0',
						}}
					>
						{props.title || ''}
					</h1>
				}
				data={props.data || []}
				columns={props.columns?.map(col => {
					return {
						...col,
						[!col.dontTruncate && !col.render && 'render']: row =>
							drawTruncatedStr(row[col.field], col.maxLength || props.maxColumnLength, col.truncateLeft),
						// Allows truncated parts of a column to be included in search
						[!col.customFilterAndSearch && 'customFilterAndSearch']: (searchStr, row) =>
							String(row[col.field]).toLowerCase().includes(searchStr.toLowerCase()),
						cellStyle: { padding: props.reducePadding ? '0 0 0 1rem' : '0 1rem' },
					};
				})}
				options={{
					draggable: false,
					padding: 'dense',
					pageSize: Math.min(10, props.data?.length || Number.MAX_SAFE_INTEGER),
					pageSizeOptions:
						props.data?.length > 10
							? [10, Math.min(25, Math.floor(props.data.length / 2)), Math.min(50, props.data.length)]
							: [],
					...props.options,

					headerStyle: {
						backgroundColor: colors.primary0,
						color: '#fff',
						height: '2.4rem',
						padding: props.reducePadding ? '0 0 0 1rem' : '0 1rem',
						fontWeight: '430',
						fontSize: '98%',
						position: 'unset', // Required to avoid padding overlapping sorting-icon
						overflow: 'hidden', // Prevents the arrow from flowing outside the table in the rightmost cell
						...props.options?.headerStyle,
					},
					rowStyle:
						typeof props.options?.rowStyle === 'function'
							? props.options.rowStyle
							: {
									whiteSpace: 'nowrap',
									...props.options?.rowStyle,
								},
				}}
				components={{
					Toolbar: tProps => (
						<MTableToolbar
							{...tProps}
							classes={makeStyles({
								root: { minHeight: props.toolbarHeight ? `${props.toolbarHeight} !important` : undefined },
							})()}
						/>
					),
					...props.components,
				}}
				localization={{
					...props.localization,
					body: { ...(props.localization || {}).body, emptyDataSourceMessage: '...' },
					toolbar: {
						...(props.localization || {}).toolbar,
						searchTooltip: t('materialTableWrapper.search'),
						searchPlaceholder: t('materialTableWrapper.search'),
					},
					pagination: {
						labelDisplayedRows: t('materialTableWrapper.displayedRows', { from: '{from}', to: '{to}', count: '{count}' }), // TODO: This really needs to be checked. Unsure how parameters are passed to this function compared to before
						labelRowsSelect: t('materialTableWrapper.rows'),
						firstTooltip: t('materialTableWrapper.firstPage'),
						previousTooltip: t('materialTableWrapper.lastPage'),
						nextTooltip: t('materialTableWrapper.nextPage'),
						lastTooltip: t('materialTableWrapper.lastPage'),
						...(props.localization || {}).pagination,
					},
				}}
				icons={{ ...materialTableIcons, ...props.icons }}
				key={props.data?.length} // Required for updates to props.data to trigger re-render
			/>
		</MuiThemeProvider>
	);
}

export default MaterialTableWrapper;
