import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, Paper, Backdrop, LinearProgress } from '@material-ui/core';
import { Lens as CircleIcon, Error as WarningCircleIcon, Star as FavoriteIcon, StarBorder as NotFavoriteIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useHistory, useLocation } from 'react-router-dom';

import { CENTER_CONTENT_STYLE } from '../constants';
import * as colors from '../colors';
import { isValueInsideThresholds } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import { updateUserInfo } from '../redux/actionCreators';
import MaterialTable from './MaterialTableWrapper';
import { CustomDivider } from '../components/CustomDivider';
import { set } from 'date-fns';
import { use } from 'i18next';

const GET_ECP_ALARMS = gql`
	query ($filter: EcpAlarmFilter) {
		getEcpAlarms(filter: $filter) {
			ecpalarmid
			externalcontrolpanelid
			label
			earliesttimestamp
			confirmtimestamp
		}
	}
`;
const SET_FAVORITE_PROPERTY_IDS = gql`
	mutation ($id: String!, $add: Boolean!, $favoritePropertyIds: [Int!]!) {
		addOrRemoveKeycloakUserAttributes(id: $id, add: $add, favoritePropertyIds: $favoritePropertyIds) {
			favoritePropertyIds
		}
	}
`;

/**
 * A table of properties and a list of cities that can be used to filter which properties are listed
 * @param {Array} properties : Properties to be listed in the table
 * @param {onPropertySelect} function : Must be called when the user selects a property
 */
function PropertyList(props) {
	const [cities, setCities] = useState([]);
	const [propsWAlarms, setPropsWAlarms] = useState([]);
	const [ecpAlarms, setEcpAlarms] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	
	const [searchValue, setSearchValue] = useState('');
	const history = useHistory();
	const location = useLocation();

	// Update the list of cities whenever the list of properties in Redux changes
	useEffect(() => {
		const cities = [];
		for (const property of props.properties)
			if (!cities.some(city => city.name === property.city)) cities.push({ name: property.city, selected: true });

		setCities(cities);
	}, [props.properties]);

	useEffect(() => {
		setPropsWAlarms(
			[
				...props.sensors.filter(sen => !isValueInsideThresholds(sen)),
				...props.externalControlPanels.filter(ecp =>
					ecpAlarms.some(ala => !ala.confirmtimestamp && ala.externalcontrolpanelid === Number(ecp.externalcontrolpanelid))
				),
			].map(obj => Number(obj.locationid))
		);
		// eslint-disable-next-line
	}, [props.sensors, ecpAlarms]);

	/* useEffect(() => {
		if (props.externalControlPanels.length && !ecpAlarms.length)
			setIsLoading(false);
	}, [props.externalControlPanels, ecpAlarms]); */

	useEffect(() => {
		if (props.properties.length) setIsLoading(false);
	}, [props.properties]);
	

	useQuery(GET_ECP_ALARMS, {
		skip: !props.externalControlPanels.length,
		variables: { filter: { externalcontrolpanelids: props.externalControlPanels.map(ecp => ecp.externalcontrolpanelid) } },
		onCompleted: ({ getEcpAlarms }) => setEcpAlarms(getEcpAlarms),
	});
	const [setFavoritePropertyIds] = useMutation(SET_FAVORITE_PROPERTY_IDS, {
		onCompleted: ({ addOrRemoveKeycloakUserAttributes }) => props.updateUserInfo(addOrRemoveKeycloakUserAttributes),
		onError: console.error,
	});

	const { t } = useTranslation();

	// Toggles the selection key of the specified city in cities, or all cities if none is specified
	function toggleCitySelection(cityName) {
		if (cityName) {
			for (const city of cities)
				if (city.name === cityName) {
					city.selected = !city.selected;
					break;
				}
		} else {
			// If a single selection is false; select all states
			const selectionState = cities.some(city => !city.selected);

			for (const city of cities) city.selected = selectionState;
		}

		setCities([...cities]);
		const selectedCities = cities.filter(city => city.selected).map(city => encodeURIComponent(city.name));
		const queryParams = `selectedCities=${selectedCities.join(',')}&search=${encodeURIComponent(searchValue)}`;
		history.push(`${location.pathname}?${queryParams}`);
	}

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const selectedCitiesParam = queryParams.get('selectedCities');
		if (selectedCitiesParam) {
			const selectedCities = selectedCitiesParam.split(',').map(city => decodeURIComponent(city));
			setCities(prevCities =>
				prevCities.map(city => ({
					...city,
					selected: selectedCities.includes(city.name),
				}))
			);
		}
		const savedSearch = queryParams.get('search');
		if (savedSearch) {
			setSearchValue(savedSearch);
		}
	}, [location.search]);

	const handleSearchChange = value => {
		setSearchValue(value);

		const selectedCities = cities.filter(city => city.selected).map(city => encodeURIComponent(city.name));
		const queryParams = `selectedCities=${selectedCities.join(',')}&search=${encodeURIComponent(value)}`;
		history.push(`${location.pathname}?${queryParams}`);
	};

	const debounceDelay = 1500;
	return (
		<div style={{ width: CENTER_CONTENT_STYLE.width, height: CENTER_CONTENT_STYLE.height, display: 'flex' }}>
			{/* Table */}
			<MaterialTable
				style={{ width: '100%', height: '38.5rem', overflow: 'hidden' }}
				title={t('constants.properties')}
				columns={[
					{
						title: t('propertyList.alarm'),
						field: 'alarms',
						render: rowData =>
							rowData.hasAlarm ? (
								<WarningCircleIcon style={{ color: colors.red, transform: 'scale(0.9)' }} />
							) : (
								<CircleIcon style={{ color: colors.green, transform: 'scale(0.9)' }} />
							),
						customSort: (a, b) => {
							if (a.hasAlarm && !b.hasAlarm) return -1;
							else if (!a.hasAlarm && b.hasAlarm) return 1;
							else if (a.city < b.city) return -1;
							else if (a.city > b.city) return 1;
							else if (a.street < b.street) return -1;
							else if (a.street > b.street) return 1;
							else return a.locationid < b.locationid ? -1 : 1;
						},
					},
					{ title: t('generic.city'), field: 'city' },
					{ title: t('generic.address'), field: 'street' },
					{ title: t('propertyList.propertyDesignation'), field: 'cadastral' },
					{ title: t('propertyList.squareMetres'), field: 'area' },
					// { title: 'Aktiva larm', field: 'activeAlarmCount' },
					// { title: 'Larm / månad', field: 'alarmsPerMonth' },
				]}
				actions={[
					row => {
						const isFavorite = props.userInfo?.favoritePropertyIds?.includes(row.locationid);
						const iconStyle = { width: '1.5rem', height: '1.5rem' };
						return {
							icon: () =>
								isFavorite ? (
									<FavoriteIcon style={{ ...iconStyle, color: '#fe6', filter: 'drop-shadow(0 0 1px #0004)' }} />
								) : (
									<NotFavoriteIcon className='notFavoriteIcon' style={{ ...iconStyle, color: '#fe6a' }} />
								),
							onClick: () =>
								setFavoritePropertyIds({
									variables: {
										id: props.userInfo?.id,
										add: !isFavorite,
										favoritePropertyIds: [row.locationid],
									},
								}),
						};
					},
				]}
				data={props.properties
					.filter(property => cities.find(city => city.name === property.city && city.selected))
					.map(dat => ({
						...dat,
						hasAlarm: propsWAlarms.includes(dat.locationid),
						area: dat.area ? dat.area + ' m²' : undefined,
					}))
					.sort((a, b) => {
						const aIsFav = props.userInfo?.favoritePropertyIds?.includes(a.locationid);
						const bIsFav = props.userInfo?.favoritePropertyIds?.includes(b.locationid);
						if (aIsFav && !bIsFav) return -1;
						else if (!aIsFav && bIsFav) return 1;
						else if (a.hasAlarm && !b.hasAlarm) return -1;
						else if (!a.hasAlarm && b.hasAlarm) return 1;
						else if (a.city < b.city) return -1;
						else if (a.city > b.city) return 1;
						else if (a.street < b.street) return -1;
						else if (a.street > b.street) return 1;
						else return a.locationid < b.locationid ? -1 : 1;
					})}
				localization={{
					toolbar: { searchTooltip: t('propertyList.search'), searchPlaceholder: t('propertyList.search') },
					header: { actions: '' },
					pagination: {
						labelDisplayedRows: t('propertyList.displayedRows', { from: '{from}', to: '{to}', count: '{count}' }),
						labelRowsSelect: t('propertyList.rows'),
						firstTooltip: t('propertyList.firstPage'),
						previousTooltip: t('propertyList.previousPage'),
						nextTooltip: t('propertyList.nextPage'),
						lastTooltip: t('propertyList.lastPage'),
					},
				}}
				maxColumnLength={26}
				options={{
					pageSize: 14,
					pageSizeOptions: [],
					headerStyle: {
						backgroundColor: colors.primary0,
						color: colors,
						height: '3.2rem',
						fontWeight: '430',
						fontSize: '98%',
					},
					debounceInterval: debounceDelay,
					search: true,
					searchText: searchValue, // Pass the search value to initialize the search
				}}
				onRowClick={(_event, rowData) => {
					props.onPropertySelect(rowData);
				}}
				onSearchChange={handleSearchChange}
			/>

			{/* Property selection list */}
			<Paper style={{ marginLeft: '1rem', height: '38.5rem', display: 'flex', flexDirection: 'column' }}>
				<h2 style={{ fontWeight: '400', margin: '1.8rem 0 0.2rem 2.2rem' }}>{t('generic.city_other')}</h2>
				<List style={{ paddingBottom: '0' }}>
					<ListItem button dense onClick={() => toggleCitySelection()} style={{ padding: '0 4rem 0 1.5rem' }}>
						<ListItemIcon>
							<Checkbox checked={cities.every(city => city.selected)} style={{color: colors.text}} />
						</ListItemIcon>
						<ListItemText primary={t('propertyList.selectAll')} />
					</ListItem>
					<CustomDivider marginOffset={1.5} marginSideOffset={-2} />
				</List>
				<List style={{ overflowY: 'auto', paddingTop: '0' }}>
					{cities
						.sort((a, b) => (a.name > b.name ? 1 : -1))
						.map(city => {
							return (
								<ListItem
									button
									dense
									key={city.name}
									onClick={() => toggleCitySelection(city.name)}
									style={{ padding: '0 3.5rem 0 1.5rem' }}
								>
									<ListItemIcon>
										<Checkbox disableRipple checked={city.selected} style={{color: colors.text}} />
									</ListItemIcon>
									<ListItemText primary={city.name} />
								</ListItem>
							);
						})}
				</List>
			</Paper>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('propertyList.propertyLoading')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</div>
	);
}

export default connect(getStateVariables(STORE.sensors, STORE.externalControlPanels, STORE.userInfo), { updateUserInfo })(PropertyList);
