import React, { useEffect, useMemo, useRef } from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import { format as formatDate } from 'date-fns';
import { ResponsiveBar } from '@nivo/bar';
import * as colors from '../../../colors';
import { getFormattedNumber } from '../../../utility-functions';
import { MINUTES_PER_DAY, MINUTES_PER_YEAR } from '../../../constants';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

const DAY_IN_MINUTES = 1440;
const MILLISECONDS_PER_SECOND = 1000;
const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * 60;
const APPROX_PX_PER_WIDTH = 92;

function formatData(data) {
	const transformedData = [];
	const formatObject = {};
	const formatUnits = {};
	data.forEach(sensor => {
		sensor.data.forEach(values => {
			formatUnits[sensor.id] = sensor.unit;
			if (formatObject[values.x] !== undefined) {
				formatObject[values.x] = { ...formatObject[values.x], [sensor.id]: values.y };
			} else {
				formatObject[values.x] = { [sensor.id]: values.y.toString() };
			}
		});
	});
	Object.keys(formatObject).forEach(timestamp => {
		transformedData.push({ date: parseInt(timestamp), ...formatObject[timestamp] });
	});
	return transformedData;
}

function getMinMaxTimestamps(data) {
	let min = undefined;
	let max = undefined;
	data.forEach(value => {
		const localMin = value.data[0]?.x;
		const localMax = value.data[value.data.length - 1]?.x;
		if (localMin < min || min === undefined) {
			min = localMin;
		}
		if (localMax > max || max === undefined) {
			max = localMax;
		}
	});
	return [min, max];
}

const viewModeStyles = props => ({
	popout: {
		margin: {
			right: 300,
			top: 20,
			bottom: 50,
			left: props.showYAxis ? 40 : 25,
		},
		enableSlices: props.data.length <= 15 ? 'x' : false,
	},
	overview: {
		margin: {
			right: Math.max(props.widthInPx / 4, 150),
			bottom: props.isHourFormat ? 48 : 70,
			left: props.showYAxis ? 50 : 25,
			top: 20,
		},
	},
	report: {
		margin: {
			right: Math.max(props.widthInPx / 4, 120),
			top: 10,
			bottom: props.isHourFormat ? 48 : 65,
			left: props.showYAxis ? 50 : 25,
		},
	},
});

function shouldXAxisDisplayHourFormat(data) {
	let min = undefined;
	let max = undefined;
	data.forEach(value => {
		const localMin = value.data[0]?.x;
		const localMax = value.data[value.data.length - 1]?.x;
		if (localMin < min || min === undefined) {
			min = localMin;
		}
		if (localMax > max || max === undefined) {
			max = localMax;
		}
	});
	return (max - min) / MILLISECONDS_PER_MINUTE <= MINUTES_PER_DAY;
}

function BarChart({ data, normalized, timeRange, viewMode, width, animation = true, dataLoadedCallback }) {
	const { t } = useTranslation();

	const widthRef = useRef();
	const isHourFormat = shouldXAxisDisplayHourFormat(data);
	const numUnits = new Set(data.reduce((agr, { unit }) => (unit != null ? [...agr, unit] : agr), [])).size;
	const showYAxis = numUnits < 2 || normalized;
	const dataIsEmpty = data.reduce((acc, curr) => (acc += curr.data.length), 0) === 0;
	const transformedData = useMemo(() => formatData(data), [data]);
	const dataUnits = data.reduce((units, sensor) => ({ ...units, [sensor.id]: sensor.unit }), {});

	const getXAxisFormat = value => {
		if (isHourFormat) {
			return formatDate(new Date(value), 'HH:mm');
		}
		return formatDate(new Date(value), 'yyyy-MM-dd');
	};

	const getToolTipFormat = value => {
		const difference = (timeRange.endDate - timeRange.startDate) / MILLISECONDS_PER_MINUTE;
		const date = new Date(value);
		if (difference <= MINUTES_PER_DAY) {
			return formatDate(date, 'yyyy-MM-dd | HH:mm');
		} else if (difference < MINUTES_PER_YEAR) {
			return formatDate(date, 'yyyy-MM-dd');
		}
		return formatDate(date, 'yyyy') + ' | ' + date.toLocaleString(i18n.language, { month: 'long' });
	};

	useEffect(() => {
		if (dataLoadedCallback !== undefined) {
			dataLoadedCallback();
		}
	}, []);

	const widthInPx = widthRef.current?.offsetWidth || APPROX_PX_PER_WIDTH * width;
	return (
		<div ref={widthRef} className='main-div' style={{ width: '100%', height: '100%', marginTop: '-15px' }}>
			{dataIsEmpty ? (
				<MuiAlert style={{ marginTop: '40px' }} elevation={2} severity='warning'>
					{t('charts.noSensorData')}
				</MuiAlert>
			) : (
				<ResponsiveBar
					data={transformedData}
					animate={animation}
					keys={data.map(({ id }) => id)}
					indexBy='date'
					groupMode='grouped'
					padding={0.3}
					valueScale={{ type: 'linear' }}
					indexScale={{ type: 'band', round: true }}
					colors={[colors.secondaryC, colors.secondaryF, colors.secondaryA, colors.secondaryB, colors.secondaryE, colors.secondaryG]}
					borderColor={{
						from: 'color',
						modifiers: [['darker', 1.6]],
					}}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 45,
						legendPosition: 'middle',
						legendOffset: 32,
						truncateTickAt: 0,
						format: value => getXAxisFormat(value),
					}}
					axisLeft={
						showYAxis
							? {
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legendPosition: 'middle',
									legendOffset: -40,
									truncateTickAt: 0,
									format: val => getFormattedNumber(val),
								}
							: null
					}
					enableLabel={false}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{
						from: 'color',
						modifiers: [['darker', 1.6]],
					}}
					tooltip={point => (
						<div className={'nivo_tooltip'} style={{ padding: '4px 8px' }}>
							{point?.id + ': ' + getFormattedNumber(parseInt(point.value)) + ' ' + (dataUnits[point.id] || '')} <br />
							<div
								style={{
									width: '100%',
									height: '0.3rem',
									background: point.color,
									margin: '0.2rem 0 0.25rem 0',
								}}
							/>
							<div style={{ color: '#444' }}>{getToolTipFormat(point.indexValue)}</div>
						</div>
					)}
					theme={{
						legends: {
							text: {
								fontSize: 11,
							},
						},
					}}
					legends={[
						{
							anchor: 'bottom-right',
							direction: 'column',
							justify: false,
							translateX: 90,
							translateY: 0,
							itemsSpacing: 0,
							itemDirection: 'left-to-right',
							itemWidth: 80,
							itemHeight: 20,
							itemOpacity: 0.75,
							symbolSize: 7,
							symbolBorderColor: 'rgba(0, 0, 0, .5)',
							effects: [
								{
									on: 'hover',
									style: {
										itemBackground: 'rgba(0, 0, 0, .03)',
										itemOpacity: 1,
									},
								},
							],
						},
					]}
					role='application'
					ariaLabel='Bar chart'
					{...viewModeStyles({ showYAxis, widthInPx, isHourFormat, data })[viewMode]}
				/>
			)}
		</div>
	);
}

export default BarChart;
