import React, { useEffect, useMemo, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format as formatDate } from 'date-fns';
import { getFormattedNumber } from '../../../utility-functions';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';

import * as colors from '../../../colors';

import { ResponsivePie } from '@nivo/pie';
import i18n from '../../../i18n';

const APPROX_PX_PER_WIDTH = 92;

const viewModeStyles = props => ({
	popout: {
		margin: {
			right: 300,
			top: 20,
			bottom: 65,
			left: 30,
		},
	},
	overview: {
		margin: {
			right: Math.max(props.widthInPx / 3, 200),
			top: 20,
			bottom: 30,
			left: 10,
		},
	},
	report: {
		margin: {
			right: Math.max(props.widthInPx / 4, 150),
			top: 10,
			bottom: 15,
			left: 5,
		},
	},
});

function averagePerSensor(data) {
	const formattedData = [];
	data.forEach(sensor => {
		formattedData.push({
			id: sensor.id,
			label: sensor.id,
			unit: sensor.unit,
			hoverLabel: sensor.id,
			hoverFooter: i18n.t('constants.mean'),
			value: sensor.data.reduce((sum, datapoint) => (sum += datapoint.y), 0) / Math.max(sensor.data.length, 1),
		});
	});
	if (formattedData.length === 1 && !formattedData[0].value) {
		return [];
	}
	return formattedData;
}

function sumPerSensor(data) {
	const formattedData = [];
	data.forEach(sensor => {
		formattedData.push({
			id: sensor.id,
			label: sensor.id,
			unit: sensor.unit,
			hoverLabel: sensor.id,
			hoverFooter: i18n.t('constants.sum'),
			value: sensor.data.reduce((sum, datapoint) => (sum += datapoint.y), 0),
		});
	});
	if (formattedData.length === 1 && !formattedData[0].value) {
		return [];
	}
	return formattedData;
}

const useStyles = makeStyles(theme => ({
	tooltip: {
		backgroundColor: '#ffffff',
		padding: '10px 20px',
	},
}));

const PieChart = ({ data, animation = true, dataLoadedCallback, dataType, viewMode, width }) => {
	const { t } = useTranslation();
	const transformedData = useMemo(() => {
		if (dataType === 'mean') return averagePerSensor(data);
		return sumPerSensor(data);
	}, [data, dataType]);
	const classes = useStyles();
	const widthRef = useRef();

	const dataIsEmpty = data.reduce((acc, curr) => (acc += curr.data.length), 0) === 0;

	useEffect(() => {
		if (dataLoadedCallback !== undefined) {
			dataLoadedCallback();
		}
	}, []);

	const widthInPx = widthRef.current?.offsetWidth || APPROX_PX_PER_WIDTH * width;

	return (
		<div className='main-div' style={{ width: '100%', height: '100%', marginTop: '-15px' }}>
			{dataIsEmpty ? (
				<MuiAlert style={{ marginTop: '40px' }} elevation={2} severity='warning'>
					{t('charts.noSensorData')}
				</MuiAlert>
			) : (
				<ResponsivePie
					data={transformedData}
					animate={animation}
					innerRadius={0.5}
					padAngle={0.7}
					cornerRadius={3}
					activeOuterRadiusOffset={8}
					borderWidth={1}
					borderColor={{
						from: 'color',
						modifiers: [['darker', 0.2]],
					}}
					arcLinkLabelsSkipAngle={10}
					arcLinkLabelsTextColor='#333333'
					enableArcLinkLabels={false}
					arcLinkLabelsThickness={2}
					arcLinkLabelsColor={{ from: 'color' }}
					arcLabelsSkipAngle={10}
					arcLabelsTextColor={{
						from: 'color',
						modifiers: [['darker', 2]],
					}}
					valueFormat= {val => getFormattedNumber(val)}
					colors={[colors.secondaryC, colors.secondaryF, colors.secondaryA, colors.secondaryB, colors.secondaryE, colors.secondaryG]}
					tooltip={value => (
						<div className={classes.tooltip}>
							{value.datum.data.hoverLabel + ': ' + value.datum.formattedValue + ' ' + value.datum.data.unit} <br />
							<div
								style={{
									width: '100%',
									height: '0.3rem',
									background: value.datum.color,
									margin: '0.2rem 0 0.25rem 0',
								}}
							/>
							<div style={{ color: '#444' }}>{value.datum.data.hoverFooter}</div>
						</div>
					)}
					legends={[
						{
							anchor: 'bottom-right',
							direction: 'column',
							justify: false,
							translateX: 100,
							translateY: transformedData.length < 11 ? 0 : 70,
							itemsSpacing: 0,
							itemDirection: 'left-to-right',
							itemWidth: 80,
							itemHeight: 20,
							itemOpacity: 0.75,
							symbolSize: 12,
							symbolShape: 'circle',
							symbolBorderColor: 'rgba(0, 0, 0, .5)',
							effects: [
								{
									on: 'hover',
									style: {
										itemBackground: 'rgba(0, 0, 0, .03)',
										itemOpacity: 1,
									},
								},
							],
						},
					]}
					role='application'
					ariaLabel='Pie chart'
					{...viewModeStyles({ widthInPx, data })[viewMode]}
				/>
			)}
		</div>
	);
};
export default PieChart;
