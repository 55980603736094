import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

import * as colors from '../colors'
import i18n from '../i18n';

const DEFAULTS = {
	enabled: false,
	title: i18n.t('confirmPopup.confirmPrompt'),
	text: '',
	confirmLabel: i18n.t('generic.confirm'),
	denyLabel: i18n.t('generic.cancel'),
	onFinish: () => {},
	style: {},
};

/**
 * Popup that asks user to confirm or deny a specified prompt
 * @param {Boolean} enabled : Whether or not the popup should show
 * @param {String} title : Header of popup window
 * @param {String} text : Text describing the purpose of the prompt
 * @param {String} confirmLabel : Label used for confirm-button
 * @param {String} denyLabel : Label used for deny-button
 * @param {Function} onFinish : Callback for when popup finishes and should be closed, parameters: (success: Whether confirm was pressed or not)
 * @param {Object} style : Applied to root element
 */
function ConfirmPopup(props) {
	const enabled = props.enabled || DEFAULTS.enabled;
	const title = props.title || DEFAULTS.title;
	const text = (typeof props.text === 'function' ? props.text() : props.text) || DEFAULTS.text;
	const confirmLabel = props.confirmLabel || DEFAULTS.confirmLabel;
	const denyLabel = props.denyLabel || DEFAULTS.denyLabel;
	const onFinish = props.onFinish || DEFAULTS.onFinish;
	const style = props.style || DEFAULTS.style;

	return (
		<Dialog open={enabled} onClose={() => onFinish()} style={style}>
			<DialogTitle>{title}</DialogTitle>

			<DialogContent>
				<DialogContentText>{text}</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button onClick={() => onFinish()} color={colors.text}>
					{denyLabel}
				</Button>
				<Button onClick={() => onFinish(true)} color={colors.text} autoFocus>
					{confirmLabel}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export { ConfirmPopup };
