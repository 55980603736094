import React, { useState } from 'react';
import { Button, IconButton, Dialog, Tooltip } from '@material-ui/core';
import {
	CheckCircle as ConfirmedIcon,
	RemoveCircle as NotConfirmedIcon,
	Add as AddIcon,
	Done as ConfirmIcon,
	ThreeDRotation as ThreeDIcon,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import { MTableToolbar } from '@material-table/core';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import * as colors from '../colors';
import { PAGES, PROPERTY_TABS, CENTER_CONTENT_STYLE } from '../constants';
import { drawTruncatedStr } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import { setDeviations } from '../redux/actionCreators';
import SelectionTable from './SelectionTable';
import EditPopup from './EditPopup';
import DialogWrapper from './DialogWrapper';
import EditableText from './EditableText';
import ThreeSixtyView from './ThreeSixtyView';

const SET_DEVIATIONS = gql`
	mutation ($deviationids: [ID!]!, $title: String, $description: String, $confirmcomment: String) {
		setDeviations(deviationids: $deviationids, title: $title, description: $description, confirmcomment: $confirmcomment) {
			deviationid
			locationid
			title
			description
			datecreated
			confirmcomment
			dateconfirmed
			digitaltwinperspectiveid
			digitaltwintagid
		}
	}
`;
const ADD_DEVIATION = gql`
	mutation ($locationid: ID!, $title: String!, $description: String) {
		addDeviation(locationid: $locationid, title: $title, description: $description) {
			deviationid
			locationid
			title
			description
			datecreated
			confirmcomment
			dateconfirmed
		}
	}
`;
const GET_DIGITALTWIN_TAGS = gql`
	query ($filter: DigitalTwinTagFilter!) {
		getDigitalTwinTags(filter: $filter) {
			digitaltwintagid
			digitaltwinid
		}
	}
`;
const GET_DIGITALTWIN_PERSPECTIVES = gql`
	query ($filter: DigitalTwinPerspectiveFilter!) {
		getDigitalTwinPerspectives(filter: $filter) {
			digitaltwinperspectiveid
			digitaltwinid
			sweep
			rotationx
			rotationy
			zoom
		}
	}
`;

function confirmedSort(a, b) {
	return (!a.dateconfirmed && b.dateconfirmed) ||
		(!a.dateconfirmed && !b.dateconfirmed && a.datecreated > b.datecreated) ||
		(a.dateconfirmed && b.dateconfirmed && a.dateconfirmed > b.dateconfirmed)
		? -1
		: 1;
}

function DeviationList(props) {
	const [showAddDeviationPopup, setShowAddDeviationPopup] = useState(false);
	const [showConfirmDeviationPopup, setShowConfirmDeviationPopup] = useState(false);
	const [selections, setSelections] = useState([]);
	const [selectedDeviationId, setSelectedDeviationId] = useState();
	const [show360Popup, setShow360Popup] = useState(false);
	const [digitalTwinSelection, setDigitalTwinSelection] = useState(); // Format: {digitalTwin: object, tagId: number, perspective: object}
	const [showTitlePopup, setShowTitlePopup] = useState(false);
	const [showDescriptionPopup, setShowDescriptionPopup] = useState(false);
	const [showCommentPopup, setShowCommentPopup] = useState(false);

	const { t } = useTranslation();

	const [setDeviations] = useMutation(SET_DEVIATIONS, {
		onCompleted: ({ setDeviations }) => {
			for (const newDev of setDeviations) {
				const devI = props.deviations.findIndex(dev => dev.deviationid === newDev.deviationid);
				if (devI !== -1) props.deviations[devI] = newDev;
			}
			props.setDeviations([...props.deviations]);
		},
	});
	const [addDeviation] = useMutation(ADD_DEVIATION, {
		onCompleted: ({ addDeviation }) => props.setDeviations([...props.deviations, addDeviation]),
	});
	const [getDigitalTwinTags] = useLazyQuery(GET_DIGITALTWIN_TAGS, {
		onCompleted: ({ getDigitalTwinTags }) => {
			if (!getDigitalTwinTags[0]) return;
			setShow360Popup(true);
			setDigitalTwinSelection({
				digitalTwin: props.digitalTwins.find(dt => dt.digitaltwinid === getDigitalTwinTags[0].digitaltwinid),
				tagId: getDigitalTwinTags[0].digitaltwintagid,
			});
		},
		fetchPolicy: 'no-cache',
	});
	const [getDigitalTwinPerspectives] = useLazyQuery(GET_DIGITALTWIN_PERSPECTIVES, {
		onCompleted: ({ getDigitalTwinPerspectives }) => {
			if (!getDigitalTwinPerspectives[0]) return;
			setShow360Popup(true);
			setDigitalTwinSelection({
				digitalTwin: props.digitalTwins.find(dt => dt.digitaltwinid === getDigitalTwinPerspectives[0].digitaltwinid),
				perspective: getDigitalTwinPerspectives[0],
			});
		},
		fetchPolicy: 'no-cache',
	});

	const routerHistory = useHistory();
	const isGlobal = props.currentPage === PAGES.deviations.id;
	const maxColumnLength = isGlobal ? 12 : 23;
	const selectedDeviation = props.deviations.find(dev => dev.deviationid === selectedDeviationId);

	return (
		<>
			<div style={isGlobal ? {} : { margin: '1rem', color: colors.text}}>
				<SelectionTable
					localization={{
						title: t('generic.deviation_other'),
						nRowsSelected: () => (
							<Button
								onClick={() => setShowConfirmDeviationPopup(!showConfirmDeviationPopup)}
								variant='outlined'
								size='small'
								style={{colors: colors.text}}
							>
								<ConfirmIcon style={{ width: '1rem', height: '1rem', margin: '0 4px 0 -2px' }} />
								{t('deviationList.tableSelTitle', { count: selections.length })}
							</Button>
						),
					}}
					data={props.deviations
						.filter(dev => isGlobal || dev.locationid === String(props.currentProperty))
						.sort(confirmedSort)
						.map(dev => ({
							...dev,
							datecreated: dev.datecreated && formatDate(dev.datecreated, 'MMM dd HH:mm'),
							dateconfirmed: dev.dateconfirmed && formatDate(dev.dateconfirmed, 'MMM dd HH:mm'),
							...((isGlobal && props.properties.find(pro => String(pro.locationid) === dev.locationid)) || {}),
						}))}
					dataId='deviationid'
					selectedIds={selections.map(dev => dev.deviationid)}
					disabledIds={props.deviations.filter(dev => dev.dateconfirmed).map(dev => dev.deviationid)}
					onSelectionChange={sel => setSelections(sel)}
					columns={[
						{
							render: row =>
								row.dateconfirmed ? (
									<ConfirmedIcon style={{ color: colors.success, padding: '0.1rem 0 0' }} />
								) : (
									<NotConfirmedIcon style={{ color: colors.failure, padding: '0.1rem 0 0' }} />
								),
							customSort: confirmedSort,
						},
						...(isGlobal ? [{ title: t('generic.city'), field: 'city' }] : []),
						...(isGlobal ? [{ title: t('generic.address'), field: 'street' }] : []),
						{
							title: t('generic.label'),
							disableClick: true,
							//sorting: false,
							render: row => (
								<div
									onClick={() => {
										setSelectedDeviationId(row.deviationid);
										setShowTitlePopup(true);
									}}
									style={{ cursor: 'pointer'}}
								>
									{drawTruncatedStr(row.title || '-', maxColumnLength)}
								</div>
							),
						},
						{
							title: t('generic.description'),
							disableClick: true,
							//sorting: false,
							render: row => (
								<div
									onClick={() => {
										setSelectedDeviationId(row.deviationid);
										setShowDescriptionPopup(true);
									}}
									style={{ cursor: 'pointer', color: colors.text }}
								>
									{drawTruncatedStr(row.description || '-', maxColumnLength)}
								</div>
							),
						},
						{ title: t('generic.created'), field: 'datecreated' },
						{ title: t('generic.acknowledged_one'), field: 'dateconfirmed' },
						{
							title: t('generic.comment'),
							disableClick: true,
							//sorting: false,
							render: row =>
								row.dateconfirmed && (
									<div
										onClick={() => {
											setSelectedDeviationId(row.deviationid);
											setShowCommentPopup(true);
										}}
										style={{ cursor: 'pointer' }}
									>
										{drawTruncatedStr(row.confirmcomment || '-', maxColumnLength)}
									</div>
								),
						},
						{
							disableClick: true,
							//sorting: false,
							render: row =>
								(row.digitaltwintagid || row.digitaltwinperspectiveid) && (
									<Tooltip title={t('deviationList.showDtConnection')}>
										<IconButton
											onClick={() => {
												if (row.digitaltwintagid)
													getDigitalTwinTags({
														variables: { filter: { digitaltwintagids: [row.digitaltwintagid] } },
													});
												else if (row.digitaltwinperspectiveid)
													getDigitalTwinPerspectives({
														variables: {
															filter: { digitaltwinperspectiveids: [row.digitaltwinperspectiveid] },
														},
													});
											}}
											style={{ padding: '3px' }}
										>
											<ThreeDIcon style={{ width: '1.5rem', height: '1.5rem', padding: '0.16rem', color: colors.text }} />
										</IconButton>
									</Tooltip>
								),
						},
					]}
					onRowClick={
						isGlobal
							? row => routerHistory.push(`/${PAGES.properties.id}/${row.locationid}/${PROPERTY_TABS.deviations.id}`)
							: undefined
					}
					style={isGlobal ? { width: CENTER_CONTENT_STYLE.width } : {}}
					tableProps={{
						maxColumnLength,
						options: {
							pageSize: isGlobal ? 16 : 12,
						},
						components: {
							Toolbar: props => (
								<MTableToolbar
									{...props}
									title={
										<div style={{ display: 'flex' }}>
											{props.title}
											<Button
												onClick={() => setShowAddDeviationPopup(!showAddDeviationPopup)}
												variant='outlined'
												size='small'
												style={{ marginLeft: '1.5rem', colors: colors.text }}
											>
												<AddIcon style={{ width: '1rem', height: '1rem', marginLeft: '-2px' }} />
												{t('deviationList.addDeviation')}
											</Button>
										</div>
									}
								/>
							),
						},
					}}
				/>
			</div>

			<EditPopup
				text={{ title: t('deviationList.addDeviation'), subtitle: t('deviationList.addDeviationSubtitle') }}
				fields={[
					{ id: 'title', label: t('generic.label'), required: true },
					{ id: 'description', label: t('generic.description'), multiline: true },
					{
						id: 'locationid',
						label: t('generic.property'),
						required: true,
						options: props.properties
							.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
							.sort((a, b) => (a.value < b.value ? -1 : 1)),
						disabled: !isGlobal,
					},
				]}
				isOpen={showAddDeviationPopup}
				onClose={() => setShowAddDeviationPopup(!showAddDeviationPopup)}
				onSave={opts => {
					setShowAddDeviationPopup(!showAddDeviationPopup);
					addDeviation({
						variables: {
							title: opts.title,
							description: opts.description,
							locationid: opts.locationid || props.currentProperty,
						},
					});
				}}
			/>

			<EditPopup
				text={{
					title: t('deviationList.ackDeviations'),
					subtitle: (
						<div style={{ fontSize: '86%', color: colors.text}}>
							{selections.map(sel => (
								<div key={sel.deviationid}>
									{sel.title}
									<br />
								</div>
							))}
						</div>
					),
					save: t('generic.acknowledge'),
				}}
				fields={[{ id: 'confirmcomment', label: t('deviationList.ackComment'), multiline: true }]}
				isOpen={showConfirmDeviationPopup}
				canSave={true}
				onClose={() => setShowConfirmDeviationPopup(!showConfirmDeviationPopup)}
				onSave={opts => {
					setShowConfirmDeviationPopup(!showConfirmDeviationPopup);
					setSelections([]);
					setDeviations({
						variables: {
							deviationids: selections.map(sel => sel.deviationid),
							confirmcomment: opts.confirmcomment || '',
						},
					});
				}}
			/>

			<DialogWrapper
				title={t('generic.label')}
				dialogProps={{ open: showTitlePopup, onClose: () => setShowTitlePopup(false) }}
				width='22rem'
				color={colors.text}
			>
				<EditableText
					text={selectedDeviation?.title}
					onSave={newText => setDeviations({ variables: { deviationids: [selectedDeviation?.deviationid], title: newText } })}
				/>
			</DialogWrapper>

			<DialogWrapper
				title={t('generic.description')}
				dialogProps={{ open: showDescriptionPopup, onClose: () => setShowDescriptionPopup(false) }}
				width='22rem'
			>
				<EditableText
					text={selectedDeviation?.description}
					onSave={newText =>
						setDeviations({ variables: { deviationids: [selectedDeviation?.deviationid], description: newText } })
					}
				/>
			</DialogWrapper>

			<DialogWrapper
				title={t('deviationList.ackComment')}
				dialogProps={{ open: showCommentPopup, onClose: () => setShowCommentPopup(false) }}
				width='22rem'
			>
				<EditableText
					text={selectedDeviation?.confirmcomment}
					onSave={newText =>
						setDeviations({ variables: { deviationids: [selectedDeviation?.deviationid], confirmcomment: newText } })
					}
				/>
				<div style={{ textAlign: 'right', marginTop: '1rem', fontWeight: '500', fontSize: '83%', color: colors.text }}>
					{formatDate(new Date(selectedDeviation?.dateconfirmed || null), 'yyyy-MM-dd HH:mm')}
				</div>
			</DialogWrapper>

			<Dialog
				open={show360Popup}
				onClose={() => setShow360Popup(false)}
				onExited={() => setDigitalTwinSelection()}
				PaperComponent={props => <>{props.children}</>}
			>
				<ThreeSixtyView digitalTwin={digitalTwinSelection?.digitalTwin} selection={digitalTwinSelection} />
			</Dialog>
		</>
	);
}

export default connect(
	getStateVariables(STORE.deviations, STORE.properties, STORE.digitalTwins, STORE.currentProperty, STORE.currentPage),
	{ setDeviations }
)(DeviationList);
