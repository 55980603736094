import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Button, TextField, IconButton } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import { TimePicker } from '@material-ui/pickers';
import { format as formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { STORE, getStateVariables } from '../redux/selectors';
import { setAlarmPeriodSensors } from '../redux/actionCreators';
import TabWrapper from './TabWrapper';
import TooltipWrapper from './TooltipWrapper';
import { CustomDivider } from './CustomDivider';
import i18n from '../i18n';

import * as colors from '../colors'

const GET_ALARMPERIODS = gql`
	query ($filter: AlarmPeriodFilter) {
		getAlarmPeriods(filter: $filter) {
			starttime
			endtime
			dayindex
			inclusive
		}
	}
`;
const APPLY_ALARMPERIODS = gql`
	mutation ($sensorids: [ID!]!, $alarmperiods: [NewAlarmPeriod!]!) {
		applyAlarmPeriods(sensorids: $sensorids, alarmperiods: $alarmperiods) {
			alarmperiodid
		}
	}
`;

const TYPES = Object.freeze({ include: 1, exclude: 2 });
const DAY_OPTIONS = Object.freeze([
	{ label: i18n.t('alarmPeriodEditor.monday_initial'), id: 1 },
	{ label: i18n.t('alarmPeriodEditor.tuesday_initial'), id: 2 },
	{ label: i18n.t('alarmPeriodEditor.wednesday_initial'), id: 3 },
	{ label: i18n.t('alarmPeriodEditor.thursday_initial'), id: 4 },
	{ label: i18n.t('alarmPeriodEditor.friday_initial'), id: 5 },
	{ label: i18n.t('alarmPeriodEditor.saturday_initial'), id: 6 },
	{ label: i18n.t('alarmPeriodEditor.sunday_initial'), id: 7 },
	{ label: i18n.t('generic.holiday'), id: 8 },
]);
const TIMEPICKER_PROPS = {
	ampm: false,
	inputVariant: 'outlined',
	cancelLabel: i18n.t('generic.cancel'),
	okLabel: i18n.t('generic.select'),
	TextFieldComponent: props => <TextField {...props} inputProps={{ style: { padding: '0.6rem 0.8rem' } }} />,
};

/**
 * Menu that allows alarm-periods to be set and edited.
 * @param {object[]} sensors : Sensors to which the saved alarm-periods should be applied to
 * @param {(callback|undefined) => void} onFinish : When save-button has been pressed: called with a callback for applying changes, else called with undefined
 */
function AlarmPeriodEditor(props) {
	const [type, setType] = useState(TYPES.include);
	const [periods, setPeriods] = useState([]); // Format: { days: DAY_OPTIONS.id[], times: Date[] }

	const [, _forceRerender] = useState();
	function forceRerender() {
		_forceRerender({});
	}
	const { t } = useTranslation();

	useQuery(GET_ALARMPERIODS, {
		// Only fetch alarm-periods if all selected sensors have the same periods set
		skip: (() => {
			const getIdStr = sen =>
				props.alarmPeriodSensors
					.filter(aps => aps.sensorid === sen?.sensorid)
					.map(aps => aps.alarmperiodid)
					.sort()
					.join();
			const idStr = getIdStr(props.sensors?.[0]);
			return props.sensors.some(sen => getIdStr(sen) !== idStr) || !idStr;
		})(),
		variables: {
			filter: {
				alarmperiodids: props.alarmPeriodSensors
					.filter(aps => aps.sensorid === props.sensors?.[0]?.sensorid)
					.map(aps => aps.alarmperiodid),
			},
		},
		onCompleted: ({ getAlarmPeriods }) => {
			const currentDate = formatDate(new Date(), 'yyyy-MM-dd ');
			const periods = {};
			for (const per of getAlarmPeriods) {
				const i = per.starttime + per.endtime;
				if (!(i in periods)) periods[i] = { days: [], times: [currentDate + per.starttime, currentDate + per.endtime] };
				periods[i].days.push(per.dayindex);
			}
			setType(getAlarmPeriods[0]?.inclusive ? TYPES.include : TYPES.exclude);
			setPeriods(Object.values(periods));
		},
	});
	const [applyAlarmPeriods] = useMutation(APPLY_ALARMPERIODS, {
		onCompleted: ({ applyAlarmPeriods }) => {
			if (!applyAlarmPeriods) return;
			const sensorIds = props.sensors?.map(sen => sen.sensorid) || [];
			props.setAlarmPeriodSensors([
				...props.alarmPeriodSensors.filter(aps => !sensorIds.includes(aps.sensorid)),
				...(sensorIds.flatMap(id => applyAlarmPeriods.map(aps => ({ alarmperiodid: aps.alarmperiodid, sensorid: id }))) || []),
			]);
		},
	});

	return (
		<div>
			<TabWrapper
				tabs={[
					{ label: t('alarmPeriodEditor.inclusive'), id: TYPES.include },
					{ label: t('alarmPeriodEditor.exclusive'), id: TYPES.exclude },
				]}
				currentTab={type}
				onChange={type => setType(type)}
			/>

			<div style={{ margin: '0.6rem 0', padding: '0.3rem 0.7rem', width: 'fit-content', background: '#def', borderRadius: '5px' }}>
				{type === TYPES.include ? t('alarmPeriodEditor.inclusiveDesc') : t('alarmPeriodEditor.exclusiveDesc')}
			</div>

			<div style={{ maxHeight: '26rem', overflowY: 'auto' }}>
				{periods.map((per, perI) => (
					<div key={Math.random()}>
						<div style={{ display: 'flex' }}>
							<div>
								<ToggleButtonGroup
									value={per.days}
									onChange={(e, vals) => {
										per.days = vals;
										forceRerender();
									}}
									style={{ height: '2.3rem', marginBottom: '0.6rem' }}
								>
									{DAY_OPTIONS.map(day => (
										<ToggleButton value={day.id} key={day.id}>
											{day.label}
										</ToggleButton>
									))}
								</ToggleButtonGroup>

								<div style={{ display: 'flex', width: 'fit-content', marginBottom: '0.6rem' }}>
									<TimePicker
										{...TIMEPICKER_PROPS}
										value={per.times[0]}
										onChange={date => {
											per.times[0] = date;
											forceRerender();
										}}
										style={{ width: '4.3rem', marginRight: '0.5rem' }}
									/>
									<div style={{ width: '1.6rem', margin: 'auto', fontSize: '108%' }}>{t('generic.to_lowercase')}</div>
									<TimePicker
										{...TIMEPICKER_PROPS}
										value={per.times[1]}
										onChange={date => {
											per.times[1] = date;
											forceRerender();
										}}
										style={{ width: '4.3rem' }}
									/>
								</div>
							</div>

							<TooltipWrapper text={t('alarmPeriodEditor.deletePeriod')}>
								<IconButton
									style={{ width: '3.2rem', height: '3.2rem', margin: 'auto 0 auto auto' }}
									onClick={() => {
										periods.splice(perI, 1);
										forceRerender();
									}}
								>
									<CloseIcon />
								</IconButton>
							</TooltipWrapper>
						</div>

						{perI + 1 !== periods.length && <CustomDivider />}
					</div>
				))}
			</div>

			<Button
				color={colors.text}
				size='small'
				variant='outlined'
				onClick={() => setPeriods([...periods, { days: [], times: [new Date('2000-01-01 06:'), new Date('2000-01-01 18:')] }])}
				style={{ display: 'flex', marginTop: '0.2rem' }}
			>
				<AddIcon style={{ width: '1rem', height: '1rem' }} />
				{t('alarmPeriodEditor.addPeriod')}
			</Button>

			<div style={{ float: 'right', marginTop: '0.2rem' }}>
				<Button variant='outlined' style={{ color: '#0006', marginRight: '0.8rem' }} onClick={() => props.onFinish?.()}>
					{t('generic.cancel')}
				</Button>
				<Button
					variant='outlined'
					color={colors.text}
					disabled={periods.some(per => !per.days.length)}
					onClick={() => {
						if (typeof props.onFinish === 'function')
							props.onFinish(() =>
								applyAlarmPeriods({
									variables: {
										sensorids: props.sensors?.map(sen => sen.sensorid) || [],
										alarmperiods: periods.flatMap(per =>
											per.days.map(day => ({
												starttime: formatDate(new Date(per.times[0]), 'HH:mm'),
												endtime: formatDate(new Date(per.times[1]), 'HH:mm'),
												dayindex: day,
												inclusive: type === TYPES.include,
											}))
										),
									},
								})
							);
					}}
				>
					{t('generic.save')}
				</Button>
			</div>
		</div>
	);
}

export default connect(getStateVariables(STORE.alarmPeriodSensors), { setAlarmPeriodSensors })(AlarmPeriodEditor);
