import chroma from 'chroma-js';

export const bgcolor = '#dddddd';
export const bgcolorSidebar = '#ffffff';
export const bgcolor2 = '#efefef';
export const text = '#444';

export const accent1 = '#39AA0D';

export const primary0 = chroma(bgcolor).brighten(0.4).hex();
//export const primary = chroma(bgcolor).brighten(0.7).hex();
export const primary = text;
export const primary2 = text; //chroma(bgcolor).brighten(1.0).hex();
export const primary3 = chroma(bgcolor).brighten(1.3).hex();

export const secondary = '#C14B0F';
export const secondaryA = '#00857F';
export const secondaryB = '#18B8D7';
export const secondaryC = '#39AA0D';
export const secondaryD = '#ee6c4d';
export const secondaryE = '#f7cea0';
export const secondaryF = '#ff9b71';
export const secondaryG = '#586978';

export const accent = '#B41D0A';
export const success = '#39AA0D';
export const failure = '#f44336';
export const disabledFg = '#00000042';
export const disabledBg = '#0000001f';
export const button = '#39AA0D';

// DEPRECATED
export const green = '#1c3';
export const red = '#f00';
export const websiteBg = '#f8f8f8';
