import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Dialog, IconButton, Backdrop, LinearProgress } from '@material-ui/core';
import { Visibility as ShowIcon } from '@material-ui/icons';
import * as colors from '../colors';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { STORE, getStateVariables } from '../redux/selectors';
import { updateDigitalTwins } from '../redux/actionCreators';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import ThreeSixtyView from './ThreeSixtyView';

const GET_DIGITALTWINTAGS = gql`
	query ($filter: DigitalTwinTagFilter!) {
		getDigitalTwinTags(filter: $filter) {
			digitaltwinid
		}
	}
`;
const SET_DIGITALTWINS = gql`
	mutation ($digitaltwinids: [ID!]!, $model: String, $locationid: Int, $label: String) {
		setDigitalTwins(digitaltwinids: $digitaltwinids, model: $model, locationid: $locationid, label: $label) {
			digitaltwinid
			model
			locationid
			label
		}
	}
`;
const ADD_DIGITALTWIN = gql`
	mutation ($model: String!, $locationid: Int, $label: String) {
		addDigitalTwin(model: $model, locationid: $locationid, label: $label) {
			digitaltwinid
			model
			locationid
			label
		}
	}
`;

const POPUP_TYPES = Object.freeze({ add: 1, edit: 2 });
const DISCRETE_CLICK_ID = 'discrete-click';

function getModelFromUrl(url) {
	if (!url) return url;
	let model;
	// Required as new URL() throws errors when invalid URL-strings are passed
	try {
		const searchParams = new URL(url).searchParams;
		model = searchParams.get('m');
		if (!model && url.includes('/models/'))
			model = url
				.substr(url.indexOf('/models/') + '/models/'.length)
				.split('?')[0]
				.split('/')[0];
	} catch (e) {
		console.warn('Could not parse digital-twin URL.');
	}
	return model || url;
}

/**
 * A table for digital-twins that allows them to be filtered and bulk-edited
 */
function ThreeSixtyAdministration(props) {
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [selectedDigitalTwins, setSelectedDigitalTwins] = useState([]);
	const [digitalTwinsUnderEdit, setDigitalTwinsUnderEdit] = useState([]);
	const [editPopupType, setEditPopupType] = useState();
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [showDigitalTwin, setShowDigitalTwin] = useState(false);
	const [shownDigitalTwin, setShownDigitalTwin] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const { t } = useTranslation();

	const digitalTwinTagQuery = useQuery(GET_DIGITALTWINTAGS, {
		skip: !props.digitalTwins.length,
		variables: { filter: { digitaltwinids: props.digitalTwins.map(mod => mod.digitaltwinid) } },
	});
	const [setDigitalTwins] = useMutation(SET_DIGITALTWINS, {
		onCompleted: ({ setDigitalTwins }) => props.updateDigitalTwins(setDigitalTwins),
	});
	const [addDigitalTwin] = useMutation(ADD_DIGITALTWIN, {
		onCompleted: ({ addDigitalTwin }) => {
			Swal.fire(t('userAdmin.success'), t('threeSixtyAdmin.viewAdded'), 'success');
			props.updateDigitalTwins([addDigitalTwin])
		}
	});

	const filteredDigitalTwins = selectedProperties.length
		? props.digitalTwins.filter(dt => selectedProperties.some(pro => pro.value === dt.locationid))
		: props.digitalTwins;
	const propertyOptions = [
		...props.properties.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` })),
		{ value: null, label: t('threeSixtyAdmin.noProperty') },
	];

	//loading 3D views
	useEffect(() => {
		if (props.digitalTwins.length > 0) {
			setIsLoading(false);
		} else {
			const timer = setTimeout(() => setIsLoading(false), 3000);
			return () => clearTimeout(timer);

		}
	}, [props.digitalTwins]);

	// eslint-disable-next-line
	useEffect(() => shownDigitalTwin && setShowDigitalTwin(true), [shownDigitalTwin]);

	return (
		<>
			<div style={{ margin: '1rem 0 0 1rem' }}>
				<div style={{ display: 'flex', margin: '0 1rem 1.5rem 0', color:colors.text}}>
					<SelectionTable
						localization={{
							title: t('threeSixtyAdmin.dViews'),
							nRowsSelected: t('threeSixtyAdmin.numberOfSelectedViews') + '{0}',
						}} // TODO: Find a better way to do this.
						key={JSON.stringify(filteredDigitalTwins)}
						data={filteredDigitalTwins
							.map(dt => ({
								...dt,
								propertyLabel: propertyOptions.find(pro => pro.value && pro.value === dt.locationid)?.label || '',
								tagCount: digitalTwinTagQuery.data?.getDigitalTwinTags
									? digitalTwinTagQuery.data.getDigitalTwinTags.filter(tag => tag.digitaltwinid === dt.digitaltwinid)
										.length
									: '',
							}))
							.sort((a, b) => (a.locationid < b.locationid || (a.locationid === b.locationid && a.label < b.label) ? -1 : 1))}
						dataId='digitaltwinid'
						onSelectionChange={dts => setSelectedDigitalTwins(dts)}
						/* onRowClick={(dt, e) => {
							if (e._dispatchInstances.some(dispatch => dispatch.stateNode?.id === DISCRETE_CLICK_ID)) return;

							setDigitalTwinsUnderEdit([dt]);
							setEditPopupType(POPUP_TYPES.edit);
							setShowEditPopup(!showEditPopup);
						}} */
						columns={[
							{
								title: t('generic.name'),
								field: 'label',
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.label}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setDigitalTwins({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													digitaltwinids: [rowData.digitaltwinid],
													label: e.target.value,
												},
											});
										}}
									/>
								),
							},
							{
								title: t('generic.property'),
								field: 'propertyLabel',
							},
							{ title: t('threeSixtyAdmin.numberOfInfoPoints'), field: 'tagCount' },
							{
								title: t('threeSixtyAdmin.idOrUrl'),
								field: 'model',
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.model}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setDigitalTwins({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													digitaltwinids: [rowData.digitaltwinid],
													model: e.target.value,
												},
											});
										}}
									/>
								),
							},
							{
								title: '',
								align: 'right',
								//sorting: false,
								render: row => (
									<IconButton
										onClick={() => setShownDigitalTwin(row)}
										style={{ padding: '0.3rem' }}
										id={DISCRETE_CLICK_ID}
									>
										<ShowIcon style={{ width: '1.2rem', height: '1.2rem', color: '#666' }} />
									</IconButton>
								),
							},
						]}
						tableProps={{ maxColumnLength: 60 }}
					/>

					<SelectionSidebar
						buttons={[
							{
								label: t('threeSixtyAdmin.addView'),
								onClick: () => {
									setEditPopupType(POPUP_TYPES.add);
									setShowEditPopup(!showEditPopup);
								},
							},
							{
								label: t('genericAdmin.editSelected'),
								onClick: () => {
									setDigitalTwinsUnderEdit(selectedDigitalTwins);
									setEditPopupType(POPUP_TYPES.edit);
									setShowEditPopup(!showEditPopup);
								},
								disabled: !selectedDigitalTwins.length,
							},
							/* {
								label: t('genericAdmin.archiveSelected'),
								onClick: () => null,
								disabled: !selectedDigitalTwins.length,
							}, */
						]}
						filters={propertyOptions}
						updateSelectedFilters={props => setSelectedProperties(props || [])}
						localization={{
							filterTitle: t('threeSixtyAdmin.filterViews'),
							filterPlaceholder: t('threeSixtyAdmin.selectProperties'),
						}}
						style={{ width: '12rem', margin: '0.05rem 0 0 1rem' }}
					/>
				</div>

				<EditPopup
					text={
						editPopupType === POPUP_TYPES.edit
							? {
								title:
									digitalTwinsUnderEdit.length === 1
										? t('threeSixtyAdmin.editView') +
										(digitalTwinsUnderEdit[0].label || t('threeSixtyAdmin.withoutName'))
										: t('threeSixtyAdmin.editSelectedViews', { count: digitalTwinsUnderEdit.length }),
								subtitle:
									digitalTwinsUnderEdit.length === 1
										? t('threeSixtyAdmin.enterNewValuesForView_one')
										: t('threeSixtyAdmin.enterNewValuesForView_any'),
								//t('threeSixtyAdmin.enterNewValuesForView', { count: digitalTwinsUnderEdit.length }),
							}
							: {
								title: t('threeSixtyAdmin.addViewDot'),
								subtitle: t('threeSixtyAdmin.enterValuesNewView'),
							}
					}
					fields={
						editPopupType === POPUP_TYPES.edit
							? [
								{
									id: 'label',
									label: t('generic.name'),
									placeholder: digitalTwinsUnderEdit[0].label ? digitalTwinsUnderEdit[0].label : '...',
									disabled: digitalTwinsUnderEdit.length > 1,
								},
								{
									id: 'locationid',
									label: t('generic.property'),
									placeholder:
										digitalTwinsUnderEdit.length === 1 && digitalTwinsUnderEdit[0].locationid
											? propertyOptions.find(pro => pro.value === digitalTwinsUnderEdit[0].locationid).label
											: '...',
									options: propertyOptions,
								},
								{
									id: 'model',
									label: t('threeSixtyAdmin.idOrUrl'),
									placeholder: digitalTwinsUnderEdit[0].model,
									disabled: digitalTwinsUnderEdit.length > 1,
								},
							]
							: [
								{
									id: 'label',
									label: t('generic.name'),
								},
								{
									id: 'locationid',
									label: t('generic.property'),
									options: propertyOptions,
								},
								{
									id: 'model',
									label: t('threeSixtyAdmin.idOrUrl'),
									required: true,
								},
							]
					}
					isOpen={showEditPopup}
					onClose={() => setShowEditPopup(!showEditPopup)}
					onSave={opts => {
						if (editPopupType === POPUP_TYPES.edit) {
							setDigitalTwins({
								variables: {
									digitaltwinids: digitalTwinsUnderEdit.map(dt => dt.digitaltwinid),
									model: getModelFromUrl(opts.model),
									locationid: opts.locationid,
									label: opts.label,
								},
							});
							Swal.fire(t('userAdmin.success'), t('threeSixtyAdmin.viewInfo'), 'success');
						} else {
							addDigitalTwin({
								variables: {
									model: getModelFromUrl(opts.model),
									locationid: opts.locationid,
									label: opts.label,
								},
							});
						}
						setShowEditPopup(!showEditPopup);
					}}
				/>
			</div>

			<Dialog
				open={showDigitalTwin}
				onClose={() => setShowDigitalTwin(false)}
				onExited={() => setShownDigitalTwin()}
				PaperComponent={props => <>{props.children}</>}
			>
				<ThreeSixtyView digitalTwin={shownDigitalTwin} />
			</Dialog>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loading3DViews')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</>
	);
}

export default connect(getStateVariables(STORE.properties, STORE.digitalTwins), { updateDigitalTwins })(ThreeSixtyAdministration);
