import React, { useState, useEffect } from 'react';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as colors from '../colors';
import { combinePaths } from '../utility-functions';
import { setTab } from '../redux/actionCreators';
import { STORE, getStateVariables } from '../redux/selectors';

const DEFAULTS = Object.freeze({ tabIndex: 0 });

/**
 * A list of horizontally aligned tabs
 * @param {object[]} tabs : Tabs to draw and handle, format [{id: String, label: String}, ...]
 * @param {number} defaultTabIndex = 0 : The tab to default to if no tab is specified in the URL
 * @param {RegExp} regexpBeforeTab : Regular-expression to look for in front of where the tab is expected, g-flag automatically set, example: /\/properties\/[0-9]+\// or '/properties/[0-9]+/'
 * @param {number} squeezeFactor : How much tabs should be squeezed to make room for more tabs
 */
function CustomTabs(props) {
	const defaultTabIndex = props.defaultTabIndex || DEFAULTS.tabIndex;
	const [currentTabIndex, setCurrentTabIndex] = useState(defaultTabIndex);

	const defaultTab = props.tabs[defaultTabIndex];

	// Selects the tab in the URL when the component first loads
	useEffect(() => {
		selectTabInUrl();
		// eslint-disable-next-line
	}, []);

	// Update selected tab if Redux tab has changed or tab in URL has changed
	useEffect(() => {
		if (props.tabs[currentTabIndex].id !== props.currentTab || getTabInUrl() !== props.tabs[currentTabIndex].id) {
			selectTabInUrl();
		}
		// eslint-disable-next-line
	}, [props.location, props.currentTab]);

	// Returns the part of the URL containing the tab and forwards
	function getTabInUrl() {
		let tabInUrl = defaultTab;
		const currentUrl = props.location.pathname;
		const matches = currentUrl.match(RegExp(props.regexpBeforeTab, 'g'));

		// Returns the string after the last match, or the default tab if no match was found
		if (matches) {
			const lastMatch = matches[matches.length - 1];
			tabInUrl = currentUrl.slice(currentUrl.lastIndexOf(lastMatch) + lastMatch.length);

			if (tabInUrl[0] === '/') tabInUrl = tabInUrl.slice(1);
			if (tabInUrl.indexOf('/') !== -1) tabInUrl = tabInUrl.slice(0, tabInUrl.lastIndexOf('/'));
			if (tabInUrl.indexOf('?') !== -1) tabInUrl = tabInUrl.slice(0, tabInUrl.lastIndexOf('?'));
		}
		return tabInUrl;
	}

	// Returns the URL-path up to the tab
	function getUrlUpToTab() {
		const currentUrl = props.location.pathname;
		const currentTab = getTabInUrl();

		return currentUrl.slice(0, currentUrl.lastIndexOf(currentTab));
	}

	// Parses the URL and logically selects the tab in it
	function selectTabInUrl() {
		const tabInUrl = getTabInUrl();

		let currentTabIndex = defaultTabIndex,
			currentTabId = defaultTab.id;

		for (const [tabI, tab] of props.tabs.entries()) {
			if (tabInUrl === tab.id) {
				currentTabIndex = tabI;
				currentTabId = tab.id;
				break;
			}
		}

		setCurrentTabIndex(Number(currentTabIndex));
		props.setTab(currentTabId);
	}

	// Called natively by NavTabBar whenever a new tab is clicked
	function onTabChange(event, value) {
		setCurrentTabIndex(value);
		props.setTab(props.tabs[value].id);
	}

	const urlUpToTab = getUrlUpToTab();
	const squeezeFactor = props.squeezeFactor || 0;

	return (
		<Tabs
			value={currentTabIndex}
			onChange={onTabChange}
			classes={makeStyles({
				root: {
					margin: '0.5rem 0 1rem 1rem',
					backgroundColor: '#fff',
				},
				indicator: {
					backgroundColor: colors.bgcolor,
					height: '2px',
					margin: '0 0 0.3rem 0',
				},
			})()}
		>
			{props.tabs.map(tab => (
				<Tab
					label={tab.label}
					component={Link}
					to={combinePaths(urlUpToTab, tab.id)}
					key={tab.id}
					disableRipple
					disabled={tab.disabled}
					classes={makeStyles({
						root: {
							color: colors.text,
							fontWeight: 900,
							minWidth: 6 - squeezeFactor + 'rem',
							textTransform: 'none',
							fontSize: '108%',
							padding: '6px ' + (12 - squeezeFactor) + 'px',
						},
						selected: {
							color: colors.accent1,
						},
					})()}
				/>
			))}
		</Tabs>
	);
}

export default withRouter(connect(getStateVariables(STORE.currentTab), { setTab })(CustomTabs));
