import React, { useEffect, useRef, useState } from 'react';

import * as colors from '../../../colors';

import ReactSpeedometer from 'react-d3-speedometer';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';

const viewModeStyles = props => ({
	overview: {
		div: {},
		speedometer: {
			width: Math.min(props.width, props.height * 1.65),
		},
	},
	report: {
		div: {
			marginTop: '-10px',
			paddingLeft: '5px',
		},
		speedometer: {
			width: Math.min(props.width, props.height * 1.6),
		},
	},
});

const SpeedoMeter = ({ data, sensorMetaData, viewMode, dataLoadedCallback }) => {
	const dataIsEmpty = data.reduce((acc, curr) => (acc += curr.data.length), 0) === 0;
	const { t } = useTranslation();
	const [smSize, setSmSize] = useState({ width: 300, height: 300 });
	const parentRef = useRef();

	function calculateOptions(data, sensorMetaData) {
		if (!data || !data.length) {
			return 0;
		}

		const yValues = data[0].data.map(item => item.y);

		const formattedValue = sensorMetaData[0].value * (sensorMetaData[0].multiplier ? sensorMetaData[0].multiplier : 1);
		let maxValue = Math.max(...yValues);
		let minValue = Math.min(...yValues);

		if (maxValue < formattedValue) {
			maxValue = formattedValue * 2;
		}
		if (minValue > formattedValue) {
			minValue = formattedValue;
		}

		return [minValue, maxValue];
	}

	const [minValue, maxValue] = calculateOptions(data, sensorMetaData);
	function formatLabel(sensorMetaData) {
		if (!sensorMetaData || !sensorMetaData.length) return '';
		/* eslint-disable no-template-curly-in-string */
		return '${value}' + (sensorMetaData[0].unit || '');
	}

	function formatValue(sensorMetaData) {
		if (!sensorMetaData || !sensorMetaData.length) {
			return 0;
		}
		const formattedValue = sensorMetaData[0].value * (sensorMetaData[0].multiplier ? sensorMetaData[0].multiplier : 1);
		return formattedValue;
	}

	useEffect(() => {
		if (dataLoadedCallback !== undefined) {
			dataLoadedCallback();
		}
	}, []);

	useEffect(() => {
		setSmSize({ height: parentRef.current?.offsetHeight, width: parentRef.current?.offsetWidth });
	}, [parentRef.current?.offsetHeight, parentRef.current?.offsetWidth]);

	const styles = viewModeStyles({ width: smSize.width, height: smSize.height })[viewMode];
	return (
		<div ref={parentRef} className='main-div' style={{ width: '100%', height: '100%', ...styles.div }}>
			{dataIsEmpty ? (
				<MuiAlert style={{ marginTop: '14px' }} elevation={2} severity='warning'>
					{t('charts.noSensorData')}
				</MuiAlert>
			) : (
				<ReactSpeedometer
					value={formatValue(sensorMetaData)}
					startColor={colors.bgcolor2}
					endColor={colors.secondaryC}
					needleColor={colors.text}
					maxValue={maxValue}
					minValue={minValue}
					width={smSize.width}
					height={smSize.height}
					segments={5}
					fluidWidth={true}
					maxSegmentLabels={5}
					valueFormat={'.1f'}
					forceRender={true}
					currentValueText={formatLabel(sensorMetaData)}
					needleTransitionDuration={4000}
					needleTransition='easeElastic'
				/>
			)}
		</div>
	);
};
export default SpeedoMeter;
