import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { SettingsOutlined as SettingsIcon, Save as SaveIcon, Close as CloseIcon } from '@material-ui/icons';

import * as colors from '../colors';

function editProps(props) {
	return {
		...props,
		onSave: typeof props.onSave === 'function' ? props.onSave : () => undefined,
		checkValidity: typeof props.checkValidity === 'function' ? props.checkValidity : () => true,
		colors: {
			value: props.colors?.value || '#222',
			activeIcon: (props.colors || {}).activeIcon || '#333',
			inactiveIcon: (props.colors || {}).inactiveIcon || '#555',
		},
	};
}

/**
 * Draws a value that can be modified with an edit/save button next to it
 * @param {string} label : Drawn above value
 * @param {string} value : Initial value to be drawn
 * @param {string} unit : Drawn after value, not editable
 * @param {(any) => void} onSave : Called with the updated value after save-button has been clicked
 * @param {(any) => boolean | undefined} checkValidity : Returns true if the input value is valid, used to verify input
 * @param {{value: string, activeIcon: string, inactiveIcon: string}} colors : Applied to various elements
 * @param {object} labelStyle : For overriding the CSS of the label
 * @param {object} editAreaStyle : For overriding the CSS of the editable value area
 */
function EditableValue(_props) {
	const [isEditing, setIsEditing] = useState(false);
	const [newValue, setNewValue] = useState('');

	const props = editProps(_props);
	const iconStyle = { color: props.colors.activeIcon, width: '1.25rem' };

	return (
		<div>
			{props.label && (
				<h3 style={{ marginBottom: '0.2rem', fontSize: '92%', fontWeight: '300', ...props.labelStyle }}>{props.label}</h3>
			)}
			<div
				style={{
					display: 'flex',
					marginLeft: '0.2rem',
					width: props.unit ? '6.3rem' : '5.3rem',
					transformOrigin: 'left',
					...props.editAreaStyle,
				}}
			>
				<TextField
					value={isEditing ? newValue : props.value}
					InputProps={props.unit && { endAdornment: <InputAdornment position='end'>{props.unit}</InputAdornment> }}
					placeholder={String(props.value)}
					inputProps={{ style: { color: props.colors.value } }}
					disabled={!isEditing}
					onChange={event => {
						const val = event.target.value.replace(',', '.');
						if (props.checkValidity(val)) setNewValue(val);
					}}
					style={{ margin: '-0.23rem 0 0 -0.2rem', color: colors.text}}
				/>
				<IconButton
					onClick={() => {
						if (isEditing && newValue) props.onSave(newValue);
						setIsEditing(!isEditing);
						setNewValue('');
					}}
					size='small'
					style={{ top: '-2px', left: '0.4rem', width: '1.8rem', height: '1.8rem' }}
				>
					{isEditing ? (
						newValue ? (
							<SaveIcon style={iconStyle} />
						) : (
							<CloseIcon style={iconStyle} />
						)
					) : (
						<SettingsIcon style={{ ...iconStyle, color: props.colors.inactiveIcon }} />
					)}
				</IconButton>
			</div>
		</div>
	);
}

export default EditableValue;
