import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { IconButton, Dialog, DialogActions, Button, ButtonGroup, TextField, LinearProgress, Fab } from '@material-ui/core';
import {
	Add as AddIcon,
	Settings as SettingsIcon,
	Clear as ClearIcon,
	GetAppRounded as DownloadIcon,
	Error as ErrorIcon,
} from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/pickers';
import { gql, useMutation } from '@apollo/client';
import { pdfjs, Document, Page } from 'react-pdf';
import { format as formatDate, addDays, addMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { PAGES, INTERVAL_VALUES, REPORT_OPTIONS } from '../constants';
import { getDifference } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import { setPage, updateUserInfo } from '../redux/actionCreators';
import * as colors from '../colors';
import MaterialTable from '../components/MaterialTableWrapper';
import EditPopup from '../components/EditPopup';
import SelectionTable from '../components/SelectionTable';
import { ConfirmPopup } from '../components/ConfirmPopup';
import TooltipWrapper from '../components/TooltipWrapper';
import { PropertySelection } from '../components/reports/PropertySelection';
import i18n from '../i18n';
import { Alert } from '@material-ui/lab';
import ReportHeader from '../components/ReportHeader';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; // Required for react-pdf to work

const REPORT_PROVIDER_URL = process.env.REACT_APP_REPORT_PROVIDER_URL;

const FREQUENCY_VALUES = {
	day: { id: 'day', label: i18n.t('generic.day') },
	week: { id: 'week', label: i18n.t('generic.week') },
	month: { id: 'month', label: i18n.t('generic.month') },
};
const GENERATION_OPTIONS = { day: 'day', week: 'week', month: 'month', other: 'other' };

const DEFAULTS = { maxRecipientRows: 5 };

const SET_REPORT_RECIPIENTS = gql`
	mutation ($id: String!, $add: Boolean!, $reportRecipients: [String!]!) {
		addOrRemoveKeycloakUserAttributes(id: $id, add: $add, reportRecipients: $reportRecipients) {
			reportRecipients
		}
	}
`;
const SET_REPORT_OPTIONS = gql`
	mutation ($ids: [String!]!, $reportOptions: [String!]!) {
		setKeycloakUsers(ids: $ids, reportOptions: $reportOptions) {
			reportOptions
		}
	}
`;
const SET_REPORT_SENSORS = gql`
	mutation ($ids: [String!]!, $reportSensors: [Int!]!) {
		setKeycloakUsers(ids: $ids, reportSensors: $reportSensors) {
			reportSensors
		}
	}
`;
const SET_REPORT_PROPERTIES = gql`
	mutation ($ids: [String!]!, $reportProperties: [Int!]!) {
		setKeycloakUsers(ids: $ids, reportProperties: $reportProperties) {
			reportProperties
		}
	}
`;

function intervalSort(a, b) {
	return a.interval === INTERVAL_VALUES.day.label
		? -1
		: a.interval === INTERVAL_VALUES.week.label && b.interval !== INTERVAL_VALUES.day.label
			? -1
			: 1;
}

function Reports(props) {
	const [selectedSensorIds, setSelectedSensorIds] = useState();
	const [selectedPageIds, setSelectedPageIds] = useState();
	const [showRecipientPopup, setShowRecipientPopup] = useState(false);
	const [showPagesPopup, setShowPagesPopup] = useState(false);
	const [showSensorsPopup, setShowSensorsPopup] = useState(false);
	const [showSensorsConfirmPopup, setShowSensorsConfirmPopup] = useState(false);
	const [recipientRemoval, setRecipientRemoval] = useState({ recipient: undefined, showPopup: false });
	const [generateSelection, setGenerateSelection] = useState();
	const [showIntervalPicker, setShowIntervalPicker] = useState(false);
	const [customInterval, setCustomInterval] = useState({}); // Format: {start: Date, end: Date}
	const [reportIntervals, setReportIntervals] = useState({}); // Format: {start: Date, end: Date}
	const [isDownloadingReport, setIsDownloadingReport] = useState(false);
	const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
	const [reportDownloadFailed, setReportDownloadFailed] = useState(false);
	const [csvDownloadFailed, setCsvDownloadFailed] = useState(false);
	const [cancelReportDownload, setCancelReportDownload] = useState(() => () => null);
	const [downloadedReport, setDownloadedReport] = useState();
	const [downloadedCsv, setDownloadedCsv] = useState();
	const [reportPageCount, setReportPageCount] = useState(0);
	const [filteredReportSensors, setFilteredReportSensors] = useState([]);

	const { t } = useTranslation();

	const [setReportRecipients] = useMutation(SET_REPORT_RECIPIENTS, {
		onCompleted: ({ addOrRemoveKeycloakUserAttributes }) => props.updateUserInfo(addOrRemoveKeycloakUserAttributes),
	});
	const [setReportOptions] = useMutation(SET_REPORT_OPTIONS, {
		onCompleted: ({ setKeycloakUsers }) => {
			props.callbacks['on-update-report-options'].forEach(cb => cb.callback());
			props.updateUserInfo(setKeycloakUsers[0]);
		},
	});
	const [setReportSensors] = useMutation(SET_REPORT_SENSORS, {
		onCompleted: ({ setKeycloakUsers }) => {
			props.callbacks['on-update-report-options'].forEach(cb => cb.callback());
			props.updateUserInfo(setKeycloakUsers[0]);
		},
	});

	const [setReportProperties] = useMutation(SET_REPORT_PROPERTIES, {
		onCompleted: ({ setKeycloakUsers }) => {
			props.callbacks['on-update-report-options'].forEach(cb => cb.callback());
			props.updateUserInfo(setKeycloakUsers[0]);
		},
	});

	useEffect(() => {
		props.setPage(PAGES.reports.id);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const sensorIds = props.sensors?.map(sen => sen.sensorid);
		setFilteredReportSensors(props.userInfo?.reportSensors?.map(id => String(id)).filter(id => sensorIds.includes(id)) || []);
	}, [props.sensors, props.userInfo]);

	function getGenerateButtonState(buttonId) {
		return buttonId === generateSelection ? 'contained' : 'outlined';
	}

	function onGenerateClick(buttonId) {
		if (buttonId === GENERATION_OPTIONS.other) {
			setCustomInterval({
				start: new Date(addDays(new Date(), -3).toISOString().slice(0, 10) + 'T00:00:00'),
				end: new Date(addDays(new Date(), -1).toISOString().slice(0, 10) + 'T23:59:59'),
			});
			setShowIntervalPicker(!showIntervalPicker);
		} else if (buttonId !== generateSelection) {
			setGenerateSelection(buttonId);
			generateReport(buttonId);
		}
	}

	function generateReport(buttonId) {
		let start, end;
		if (buttonId === GENERATION_OPTIONS.day) {
			start = end = addDays(new Date(), -1).toISOString().slice(0, 10);
		} else if (buttonId === GENERATION_OPTIONS.week) {
			start = addDays(new Date(), -7).toISOString().slice(0, 10);
			end = addDays(new Date(), -1).toISOString().slice(0, 10);
		} else if (buttonId === GENERATION_OPTIONS.month) {
			start = addMonths(new Date(), -1).toISOString().slice(0, 10);
			end = addDays(new Date(), -1).toISOString().slice(0, 10);
		} else if (buttonId === GENERATION_OPTIONS.other) {
			start = formatDate(customInterval.start, "yyyy-MM-dd'T'HH:mm");
			end = formatDate(customInterval.end, "yyyy-MM-dd'T'HH:mm");
		}

		cancelReportDownload();
		setReportIntervals({ start: new Date(start), end: new Date(end) });
		setIsDownloadingReport(true);
		setIsDownloadingCsv(true);
		setReportDownloadFailed(false);
		setCsvDownloadFailed(false);

		axios
			.get(REPORT_PROVIDER_URL, {
				params: { start: start, end: end, user: 'AUTHORIZATION-TOKEN' },
				headers: { authorization: props.userInfo?.accessToken },
				responseType: 'arraybuffer',
				cancelToken: new axios.CancelToken(cancel => setCancelReportDownload(() => cancel)),
			})
			.then(res => {
				setIsDownloadingReport(false);
				setReportPageCount(1); // Required, since a value greater than actual page-count will make react-pdf throw error. Page-count is updated later
				setDownloadedReport(res.data);
			})
			.catch(e => {
				if (!axios.isCancel(e)) {
					setIsDownloadingReport(false);
					setReportDownloadFailed(true);
					console.warn('Error fetching the report to be generated.', e);
				}
			});

		axios
			.get(REPORT_PROVIDER_URL + '/csv', {
				params: { start: start, end: end, user: 'AUTHORIZATION-TOKEN' },
				headers: { authorization: props.userInfo?.accessToken },
				responseType: 'text',
				cancelToken: new axios.CancelToken(cancel => setCancelReportDownload(() => cancel)),
			})
			.then(res => {
				setDownloadedCsv(res.data);
				setIsDownloadingCsv(false);
			})
			.catch(e => {
				if (!axios.isCancel(e)) {
					setIsDownloadingCsv(false);
					setCsvDownloadFailed(true);
					console.warn('Error fetching the report to be generated.', e);
				}
			});
	}

	const tableProps = {
		header: (title, buttonTooltip, buttonIcon, buttonCallback) => (
			<div>
				{/* Copied from native title styles */}
				<h2 style={{ fontSize: '1.25rem', fontWeight: '500', lineHeight: '1.6', letterSpacing: '0.0075em' }}>{title}</h2>
				<TooltipWrapper text={buttonTooltip}>
					<IconButton
						onClick={buttonCallback}
						style={{ padding: '0.4rem', position: 'absolute', top: '0.35rem', right: '0.8rem' }}
					>
						{buttonIcon === AddIcon ? (
							<AddIcon style={{ width: '1.8rem', height: '1.8rem', color: colors.text }} />
						) : (
							<SettingsIcon style={{ width: '1.8rem', height: '1.8rem', padding: '0.2rem', color: colors.text }} />
						)}
					</IconButton>
				</TooltipWrapper>
			</div>
		),
		options: {
			search: false,
			pageSize: DEFAULTS.maxRecipientRows,
			pageSizeOptions: [],
			headerStyle: {
				backgroundColor: colors.primary0,
				color: colors.text,
				height: '2.8rem',
				fontWeight: '430',
				fontSize: '98%',
			},
			rowStyle: {
				color: colors.text,
				height: '2rem',
				whiteSpace: 'nowrap',
			},
			actionsColumnIndex: -1,
		},
		components: data => ({
			[(data?.length || 0) <= DEFAULTS.maxRecipientRows ? 'Pagination' : '']: () => null,
		}),
		style: { width: '24rem', margin: '0 0 1.3rem 2rem', overflow: 'hidden' },
	};
	const reportStyle = { width: '545px', widthNumber: 545 };
	const dateTimePickerProps = {
		disableFuture: true,
		hideTabs: true,
		ampm: false,
		inputVariant: 'outlined',
		cancelLabel: t('generic.cancel'),
		okLabel: t('generic.select'),
		TextFieldComponent: props => <TextField {...props} inputProps={{ style: { padding: '0.8rem 1rem' } }} />,
	};

	return (
		<>
			{/* All-the-time visible elements */}
			<div style={{ display: 'flex' }}>
				{/* PDF report viewer w/ buttons */}
				<div>
					<div style={{ display: 'flex', marginBottom: '0.6rem' }}>
						<div style={{ margin: 'auto auto auto 0.1rem', fontSize: '130%', fontWeight: '300' }}>
							{t('reports.generateForHeader')}
						</div>
						<div style={{ margin: 'auto 0 auto auto' }}>
							<ButtonGroup style={{color:colors.text}}>
								<Button
									variant={getGenerateButtonState(GENERATION_OPTIONS.day)}
									onClick={() => onGenerateClick(GENERATION_OPTIONS.day)}
								>
									{t('generic.day')}
								</Button>
								<Button
									variant={getGenerateButtonState(GENERATION_OPTIONS.week)}
									onClick={() => onGenerateClick(GENERATION_OPTIONS.week)}
								>
									{t('generic.week')}
								</Button>
								<Button
									variant={getGenerateButtonState(GENERATION_OPTIONS.month)}
									onClick={() => onGenerateClick(GENERATION_OPTIONS.month)}
								>
									{t('generic.month')}
								</Button>
							</ButtonGroup>
							<Button
								variant={getGenerateButtonState(GENERATION_OPTIONS.other)}
								onClick={() => onGenerateClick(GENERATION_OPTIONS.other)}
								style={{ marginLeft: '0.5rem', top: '-1px', color: colors.text }}
							>
								{t('reports.otherInterval')}
							</Button>
						</div>
					</div>

					{/* Generating report loading-bar popup & Download report button */}
					<div style={{ position: 'relative' }}>
						<div
							style={{
								position: 'absolute',
								left: '50%',
								transform: 'translateX(-50%)',
								zIndex: '10',
								marginTop: '1.5rem',
							}}
						>
							{isDownloadingReport || isDownloadingCsv || (reportDownloadFailed && csvDownloadFailed) ? (
								<div
									style={{
										width: 'max-content',
										background: '#fff',
										padding: '1rem',
										borderRadius: '0.3rem',
										boxShadow: '0rem 0.1rem 0.5rem #0008',
									}}
								>
									{reportDownloadFailed && csvDownloadFailed ? (
										<div style={{ display: 'flex' }}>
											<ErrorIcon style={{ color: '#f00' }} />
											<div style={{ margin: 'auto auto auto 0.6rem', fontSize: '112%', fontWeight: '300' }}>
												{t('reports.failedMsg')}
											</div>
										</div>
									) : (
										<>
											<div
												style={{
													margin: '-0.4rem 0 0.4rem',
													fontSize: '112%',
													fontWeight: '300',
													textAlign: 'center',
												}}
											>
												{t('reports.loadingMsg')}
											</div>
											<LinearProgress style={{ width: '16rem', height: '0.5rem', borderRadius: '0.2rem' }} />
										</>
									)}
								</div>
							) : (
								downloadedReport && (
									<Fab
										variant='extended'
										onClick={() => {
											const link = document.createElement('a');
											link.href = URL.createObjectURL(new Blob([downloadedReport]));
											link.download =
												//'rapport-' +
												t('generic.report') +
												formatDate(reportIntervals.start, 'yyyyMMdd') +
												(reportIntervals.start
													.toISOString()
													.startsWith(reportIntervals.end.toISOString().slice(0, 10))
													? ''
													: formatDate(reportIntervals.end, '-yyyyMMdd')) +
												'.pdf';
											link.dispatchEvent(new MouseEvent('click'));
										}}
										style={{ height: '2.6rem', color:colors.text}}
									>
										<DownloadIcon style={{ marginRight: '0.5rem' }} />
										{t('generic.download')}
									</Fab>
								)
							)}
						</div>
					</div>

					<div style={{ overflow: 'hidden', borderRadius: '0.28rem', boxShadow: '0 0.1rem 0.3rem #0005' }}>
						{downloadedReport ? undefined : (
							<Button
								style={{
									backgroundColor: '#2C3E75',
									color: 'white',
									padding: '1rem 2rem',
									fontSize: '1.3rem',
									border: 'none',
									cursor: 'auto',
									borderRadius: '5px',
									boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
									textTransform: 'none',
									position: 'absolute',
									filter: isDownloadingReport ? 'blur(0.1rem)' : undefined,
									margin: '15rem 0 0 10rem',
									zIndex: 1,
								}}
							>
								{t('constants.exampleReport')}
							</Button>
						)}
						<div
							style={{
								filter: isDownloadingReport
									? 'blur(0.1rem) opacity(0.8)' // While "generating report"
									: downloadedReport
										? undefined // A report has been generated
										: 'blur(0.03rem) opacity(0.8)', // Showing the preview pdf
								height: '38.3rem',
								background: '#fff',
								overflowY: 'scroll',
							}}
						>
							{downloadedReport ? undefined : <ReportHeader {...{ customer: props.userInfo.customerName }} />}
							<Document
								file={downloadedReport || require('../assets/reports/exampleReportBody2.pdf').default}
								loading={<div style={{ width: reportStyle.width }} />}
								noData={<div style={{ width: reportStyle.width }} />}
								error={
									<div style={{ width: reportStyle.width, paddingTop: '2rem', textAlign: 'center', fontWeight: '500' }}>
										{t('reports.errorOccurred')}
										<br />
										{t('reports.couldNotGenerate')}
									</div>
								}
								onLoadSuccess={({ numPages }) => setReportPageCount(numPages)}
							>
								{Array.from(new Array(reportPageCount), (_, i) => (
									<Page key={`page_${i + 1}`} pageNumber={i + 1} width={reportStyle.widthNumber} />
								))}
							</Document>
							{reportDownloadFailed !== csvDownloadFailed && (csvDownloadFailed || reportDownloadFailed) ? (
								<div
									style={{
										marginLeft: '0.5rem',
										position: 'absolute',
										width: '33rem',
										zIndex: 1,
										top: '40rem',
									}}
								>
									<Alert severity='error'>
										{t('reports.couldNotGenerateAlert', { file: reportDownloadFailed ? 'PDF' : 'CSV' })}{' '}
										{csvDownloadFailed && !selectedSensorIds?.length ? t('reports.csvIncludeProperties') : ''}
									</Alert>
								</div>
							) : undefined}
						</div>
					</div>
				</div>

				{/* Tables */}
				<div>
					{/* Recipient-table */}
					<MaterialTable
						title={tableProps.header(t('reports.reportRecipients'), t('reports.addRecipient'), AddIcon, () =>
							setShowRecipientPopup(!showRecipientPopup)
						)}
						columns={[
							{ title: t('generic.email'), field: 'email' },
							{ title: t('generic.frequency'), field: 'interval', customSort: intervalSort },
						]}
						data={
							props.userInfo?.reportRecipients
								?.map(rec => {
									const splits = rec.split(';');
									return {
										email: splits[0],
										interval: Object.values(FREQUENCY_VALUES).find(obj => obj.id === splits[1])?.label,
										rawValue: rec,
									};
								})
								?.sort(intervalSort) || []
						}
						actions={[
							{
								icon: ClearIcon,
								tooltip: t('reports.removeRecipient'),
								onClick: (e, row) => setRecipientRemoval({ recipient: row, showPopup: true }),
							},
						]}
						options={tableProps.options}
						localization={{ header: { actions: '' } }}
						components={tableProps.components(props.userInfo?.reportRecipients)}
						style={tableProps.style}
						maxColumnLength={28}
						toolbarHeight='3.3rem'
					/>

					{/* Included pages */}
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							height: '3rem',
							margin: '0 0 1.3rem 2rem',
							paddingLeft: '24px',
							background: '#fff',
							borderRadius: '4px',
							boxShadow:
								'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
						}}
					>
						<h2
							style={{
								display: 'flex',
								fontSize: '1.25rem',
								fontWeight: '500',
								lineHeight: '1.6',
								letterSpacing: '0.0075em',
							}}
						>
							{t('reports.extraPagesHeader')}
							<div style={{ marginLeft: '0.6rem' }}>
								{props.userInfo.reportOptions?.filter(opt => opt.endsWith('_PAGE')).length || 0}
							</div>
						</h2>
						<TooltipWrapper text={t('reports.changeExtraPages')}>
							<IconButton
								onClick={() => setShowPagesPopup(true)}
								style={{ margin: 'auto 0.8rem auto auto', padding: '0.4rem' }}
							>
								<SettingsIcon style={{ width: '1.8rem', height: '1.8rem', padding: '0.2rem', color: '#555' }} />
							</IconButton>
						</TooltipWrapper>
					</div>
					{/* Sensor-table */}
					<MaterialTable
						title={tableProps.header(t('reports.includedSensors'), t('reports.changeSensors'), SettingsIcon, () => {
							setShowSensorsPopup(true);
							setSelectedSensorIds(undefined);
						})}
						columns={[
							{ title: t('generic.name'), field: 'name' },
							{ title: t('generic.address'), field: 'street' },
							{ title: t('generic.city'), field: 'city' },
						]}
						data={
							filteredReportSensors
								.map(id => {
									const sensor = props.sensors.find(sen => sen.sensorid === id);
									return {
										name: sensor?.name || '',
										street: sensor?.street || '',
										city: sensor?.city || '',
									};
								})
								.sort((a, b) =>
									a.city !== b.city
										? a.city < b.city
											? -1
											: 1
										: a.street !== b.street
											? a.street < b.street
												? -1
												: 1
											: a.name < b.name
												? -1
												: 1
								) || []
						}
						options={tableProps.options}
						components={tableProps.components(filteredReportSensors)}
						style={tableProps.style}
						maxColumnLength={14}
						toolbarHeight='3.3rem'
					/>
					{/* Property-table */}
					<PropertySelection
						properties={props.userInfo?.reportProperties}
						handleSave={properties =>
							setReportProperties({
								variables: { ids: [props.userInfo?.id], reportProperties: properties },
							})
						}
					/>
					{/* <MaterialTable
						title={tableProps.header('Hejsan', 'Dåsan', SettingsIcon, () => {
							setShowSensorsPopup(true);
							setSelectedSensorIds(undefined);
						})}
						columns={[
							{ title: 'id', field: 'locationid' },
							{ title: t('generic.name'), field: 'name' },
							{ title: t('generic.address'), field: 'street' },
							{ title: t('generic.city'), field: 'city' },
						]}
						data={
							filteredReportSensors
								.map(id => {
									const sensor = props.sensors.find(sen => sen.sensorid === id);
									return {
										locationid: sensor?.locationid || null,
										name: sensor?.name || '',
										street: sensor?.street || '',
										city: sensor?.city || '',
									};
								})
								.sort((a, b) =>
									a.city !== b.city
										? a.city < b.city
											? -1
											: 1
										: a.street !== b.street
											? a.street < b.street
												? -1
												: 1
											: a.name < b.name
												? -1
												: 1
								) || []
						}
						options={tableProps.options}
						components={tableProps.components(filteredReportSensors)}
						style={tableProps.style}
						maxColumnLength={14}
						toolbarHeight='3.3rem'
					/> */}
				</div>
			</div>

			{/* Popups */}
			{/* Custom report generation interval popup */}
			<EditPopup
				text={{
					title: t('reports.selectTimeIntervals'),
					subtitle: t('reports.selectTimeIntervalsMsg'),
					save: t('reports.generate'),
				}}
				fields={[
					{
						id: 'start',
						label: t('reports.beginning'),
						customRender: (
							<DateTimePicker
								{...dateTimePickerProps}
								value={customInterval.start}
								onChange={date => setCustomInterval({ ...customInterval, start: date })}
								style={{ marginBottom: '0.5rem' }}
							/>
						),
					},
					{
						id: 'end',
						label: t('reports.end'),
						customRender: (
							<DateTimePicker
								{...dateTimePickerProps}
								value={customInterval.end}
								onChange={date => setCustomInterval({ ...customInterval, end: date })}
							/>
						),
					},
				]}
				isOpen={showIntervalPicker}
				canSave={customInterval.start < customInterval.end}
				onClose={() => setShowIntervalPicker(!showIntervalPicker)}
				onSave={() => {
					setShowIntervalPicker(!showIntervalPicker);
					setGenerateSelection(GENERATION_OPTIONS.other);
					generateReport(GENERATION_OPTIONS.other);
				}}
				dialogStyle={{ maxWidth: '20rem' }}
			/>

			{/* Add recipient popup */}
			<EditPopup
				text={{
					title: t('reports.addRecipient'),
					subtitle: t('reports.addRecipientMsg'),
				}}
				fields={[
					{ id: 'email', label: t('generic.email'), elementType: 'email', required: true },
					{
						id: 'frequency',
						label: t('generic.frequency'),
						options: [
							{ value: FREQUENCY_VALUES.day.id, label: FREQUENCY_VALUES.day.label },
							{ value: FREQUENCY_VALUES.week.id, label: FREQUENCY_VALUES.week.label },
							{ value: FREQUENCY_VALUES.month.id, label: FREQUENCY_VALUES.month.label },
						],
						required: true,
					},
				]}
				isOpen={showRecipientPopup}
				onClose={() => setShowRecipientPopup(!showRecipientPopup)}
				onSave={options => {
					setReportRecipients({
						variables: {
							id: props.userInfo.id,
							add: true,
							reportRecipients: [options.email + ';' + options.frequency],
						},
					});
					setShowRecipientPopup(!showRecipientPopup);
				}}
			/>

			{/* Change pages popup */}
			<Dialog open={showPagesPopup} onClose={() => setShowPagesPopup(false)} PaperProps={{ style: { maxWidth: '32.529rem' } }}>
				<SelectionTable
					localization={{ title: t('reports.selectExtraPages') }}
					data={Object.values(REPORT_OPTIONS)}
					dataId='id'
					preselectedIds={props.userInfo.reportOptions || []}
					columns={[{ title: t('reports.page'), field: 'name' }]}
					showPagination={false}
					onSelectionChange={rows => setSelectedPageIds(rows.map(row => row.id))}
					style={{ boxShadow: 'none' }}
					tableProps={{
						options: {
							showTextRowsSelected: false,
						},
					}}
				/>

				<DialogActions>
					<Button onClick={() => setShowPagesPopup(false)} style={{color:colors.text}}>
						{t('generic.cancel')}
					</Button>
					<Button
						disabled={!selectedPageIds || !getDifference(selectedPageIds, props.userInfo.reportOptions, undefined, true)}
						onClick={() => {
							setShowPagesPopup(false);
							setReportOptions({
								variables: {
									ids: [props.userInfo.id],
									reportOptions: [
										...(props.userInfo.reportOptions || []).filter(opt => !opt.endsWith('_PAGE')),
										...selectedPageIds,
									],
								},
							});
						}}
						styple={{color:colors.text}}
					>
						{t('generic.save')}
					</Button>
				</DialogActions>
			</Dialog>

			{/* Change sensors popup */}
			<Dialog
				open={showSensorsPopup}
				onClose={() => setShowSensorsPopup(false)}
				PaperProps={{ style: { width: '50rem', maxWidth: '50rem' } }}
			>
				<SelectionTable
					localization={{ title: t('reports.selectSensorsReport'), nRowsSelected: t('reports.numberSelectedSensors') + '{0}' }}
					data={props.sensors}
					dataId='sensorid'
					preselectedIds={filteredReportSensors}
					columns={[
						{ title: t('generic.name'), field: 'name' },
						{ title: t('reports.group'), field: 'groupname' },
						{ title: t('generic.address'), field: 'street' },
						{ title: t('generic.city'), field: 'city' },
					]}
					pageSizeOptions={[10, 15, 20]}
					onSelectionChange={rows => setSelectedSensorIds(rows.map(row => row.sensorid))}
					style={{ boxShadow: 'none' }}
					tableProps={{ maxColumnLength: 32 }}
				/>

				<DialogActions>
					<Button onClick={() => setShowSensorsPopup(false)} style={{color:colors.text}}>
						{t('generic.cancel')}
					</Button>
					<Button
						disabled={!(selectedSensorIds && getDifference(selectedSensorIds, filteredReportSensors, undefined, true))}
						onClick={() => {
							if (selectedSensorIds) setShowSensorsConfirmPopup(true);
							else setShowSensorsPopup(false);
						}}
						style={{color:colors.text}}
					>
						{t('generic.save')}
					</Button>
				</DialogActions>
			</Dialog>

			{/* Confirm user removal popup */}
			<ConfirmPopup
				enabled={recipientRemoval.showPopup}
				title={t('reports.confirmRemovalUser')}
				text={
					<>
						{'Rapporter kommer inte längre skickas till '}
						<b>{recipientRemoval.recipient?.email}</b>
						{' var '}
						<b>{recipientRemoval.recipient?.interval?.toLowerCase()}</b>
						{'.'}
					</>
				} // TODO: I need to see this in the website to translate it properly. Context is difficult to understand.
				onFinish={confirm => {
					setRecipientRemoval({ ...recipientRemoval, showPopup: false });
					if (confirm) {
						setReportRecipients({
							variables: { id: props.userInfo?.id, add: false, reportRecipients: [recipientRemoval.recipient?.rawValue] },
						});
					}
				}}
			/>

			{/* Confirm sensor change popup */}
			<ConfirmPopup
				enabled={showSensorsConfirmPopup}
				title={t('reports.confirmChangeSensors')}
				text={() => {
					const diffs = getDifference(filteredReportSensors, selectedSensorIds);
					if (diffs.added.length || diffs.removed.length)
						return (
							<>
								{diffs.added.length ? <b>{diffs.added.length}</b> : ''}
								{diffs.added.length ? t('reports.addedSensor_any') : ''}
								{diffs.added.length && diffs.removed.length ? t('reports.and') : ''}
								{diffs.removed.length ? <b>{diffs.removed.length}</b> : ''}
								{diffs.removed.length ? t('reports.removedSensor_any', { count: diffs.added.length }) : ''}
								{'.'}
							</>
						);
					// TODO: This one seems unstable for the future. Maybe redo this one fully in i18n-next.
					else return t('reports.noChangesFound');
				}}
				onFinish={confirm => {
					setShowSensorsConfirmPopup(false);
					if (confirm) {
						setShowSensorsPopup(false);
						setReportSensors({
							variables: { ids: [props.userInfo?.id], reportSensors: selectedSensorIds.map(id => Number(id)) },
						});
					}
				}}
			/>
		</>
	);
}

export default connect(getStateVariables(STORE.userInfo, STORE.sensors, STORE.callbacks), { setPage, updateUserInfo })(Reports);
