import React, { useState, useRef, useEffect } from 'react';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Button, Backdrop, LinearProgress } from '@material-ui/core';
import { CheckCircle as CheckIcon, Edit as EditIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { createDeepCopy, editObject } from '../utility-functions';
import { STORE, getStateVariables } from '../redux/selectors';
import { setSensors, setSensorGroups } from '../redux/actionCreators';
import { UNIT_CLASSIFICATIONS, PROP_SELECTION_TYPES } from '../constants';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import DialogWrapper from './DialogWrapper';
import AlarmPeriodEditor from './AlarmPeriodEditor';
import { ConfirmPopup } from './ConfirmPopup';
import * as colors from '../colors'
import { sk } from 'date-fns/locale';
import { set } from 'date-fns';

const SENSOR_TYPE = Object.freeze({ registered: 'registered', unregistered: 'unregistered' });
const SIDEBAR_STYLE = { width: '12rem', margin: '0.05rem 0 0 1rem' };

const GET_SENSORS = gql`
	query ($filter: SensorFilter) {
		getSensors(filter: $filter) {
			sensorid
			sensorref
		}
	}
`;

const GET_SENSOR_DATA = gql`
	query {
		getSensorView {
			sensorid
			sensorref
			name
			unit
			classification
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			periodicity
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
			value
			timestamp
			sensorgroupid
			groupname
			multiplier
			alarmgraceminutes
			digitaltwintagid
			digitaltwinperspectiveid
		}
	}
`;
const GET_SENSOR_VIEW = gql`
	query ($filter: SensorViewFilter) {
		getSensorView(filter: $filter) {
			sensorid
			sensorref
			name
			unit
			classification
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			periodicity
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
			value
			timestamp
			sensorgroupid
			groupname
			multiplier
			alarmgraceminutes
			digitaltwintagid
			digitaltwinperspectiveid
		}
	}
`;
const SET_SENSORS = gql`
	mutation (
		$sensorids: [ID]!
		$sensorref: String
		$name: String
		$locationid: Int
		$sensorgroupid: Int
		$classification: String
		$unit: String
		$multiplier: Float
		$alarmgraceminutes: Int
	) {
		setSensors(
			sensorids: $sensorids
			sensorref: $sensorref
			name: $name
			locationid: $locationid
			sensorgroupid: $sensorgroupid
			classification: $classification
			unit: $unit
			multiplier: $multiplier
			alarmgraceminutes: $alarmgraceminutes
		) {
			sensorid
			sensorref
			name
			locationid
			sensorgroupid
			classification
			unit
			multiplier
			alarmgraceminutes
		}
	}
`;

const ADD_SENSORGROUP = gql`
	mutation ($name: String!, $locationid: Int!) {
		addSensorGroup(name: $name, locationid: $locationid) {
			sensorgroupid
			name
			locationid
		}
	}
`;
/* const REMOVE_SENSORS = gql`
	mutation ($sensorids: [ID]) {
		removeSensors(sensorids: $sensorids) {
			sensorid
			locationid
		}
	}
`; */

const ADD_SENSOR = gql`
	mutation ($name: String, $locationid: Int, $sensorref: String) {
		addSensor(name: $name, locationid: $locationid, sensorref: $sensorref) {
			sensorref
			name
			locationid
			classification
		}
	}
`;

const POPUP_TYPES = Object.freeze({ edit: 1, delete: 2 });

/**
 * Two tables for unregistered and registered sensors that allow them to be filtered and bulk-edited
 */
function SensorAdministration(props) {
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [unregSelectedProperties, setUnregSelectedProperties] = useState([]);
	const [selectedSensors, setSelectedSensors] = useState([]);
	const [unregSelectedSensors, setUnregSelectedSensors] = useState([]);
	const [sensorsUnderEdit, setSensorsUnderEdit] = useState([]);
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [unregSensors, setUnregSensors] = useState([]);
	const [showAlarmPeriodPopup, setShowAlarmPeriodPopup] = useState(false);
	const [apCb, setApCb] = useState();
	const [editPopupSelectedPropertyId, setEditPopupSelectedPropertyId] = useState();
	const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
	const [sensoridsToDelete, setSensoridsToDelete] = useState([]);
	const sensorGroupQueue = useRef([]).current; // Format: {name: string, locationid: number, sensorIds: object[]}
	const [isLoading, setIsLoading] = useState(true);
	const [editPopupType, setEditPopupType] = useState();
	const [refresh, setRefresh] = useState(0);

	const [sensorData, setSensorData] = useState([]);
	const [exportedData, setExportedData] = useState([]);

	const { data } = useQuery(GET_SENSOR_DATA);

	useEffect(() => {
		if (data && data.getSensorView) {
			setSensorData(data.getSensorView);
			// setExportedData(data.getSensorView);
		}
	}, [data]);

	useEffect(() => {
		if (exportedData.length > 0) {
			// Download the Excel file when the exportedData is available
			exportToExcel(exportedData);
		}
	}, [exportedData]);

	useEffect(() => {
		if (editPopupEnabled) setEditPopupSelectedPropertyId(sensorsUnderEdit[0].locationid);
		// eslint-disable-next-line
	}, [editPopupEnabled]);

	useQuery(GET_SENSORS, {
		variables: { filter: { locationids: [null] } },
		skip: props.userInfo?.propertyAccess !== PROP_SELECTION_TYPES.all.id || unregSensors.length,
		onCompleted: ({ getSensors }) => setUnregSensors(createDeepCopy(getSensors)),
	});
	const [getSensorView] = useLazyQuery(GET_SENSOR_VIEW, {
		onCompleted: ({ getSensorView }) => {
			for (const newSen of getSensorView) {
				const oldSen = props.sensors.find(sen => sen.sensorid === newSen.sensorid);
				if (oldSen) editObject(oldSen, newSen);
				else props.sensors.push(newSen);
			}
			props.setSensors([...props.sensors]);
		},
	});
	const [setSensors] = useMutation(SET_SENSORS, {
		onCompleted: ({ setSensors }) => {
			//Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorInfo'), 'success');

			if (!setSensors) return;

			const updatedSensors = setSensors.filter(sen => sen.locationid);
			let newSensorIds = [];
			for (const newSen of updatedSensors) {
				const oldSen = props.sensors.find(sen => sen.sensorid === newSen.sensorid);
				if (oldSen) {
					if (oldSen.locationid !== newSen.locationid)
						editObject(
							oldSen,
							props.properties.find(pro => pro.locationid === newSen.locationid)
						);
					if (oldSen.sensorgroupid !== newSen.sensorgroupid)
						oldSen.groupname = props.sensorGroups.find(grp => grp.sensorgroupid === newSen.sensorgroupid)?.groupname;
					if (oldSen.multiplier !== newSen.multiplier) oldSen.value *= newSen.multiplier / oldSen.multiplier;
					editObject(oldSen, newSen);
				} else {
					newSensorIds.push(newSen.sensorid);
					const unregSenI = unregSensors.findIndex(sen => sen.sensorid === newSen.sensorid);
					if (unregSenI !== -1) unregSensors.splice(unregSenI, 1);
				}
			}

			const updatedUnregSensors = setSensors.filter(sen => !sen.locationid);
			const previousUnregSenCount = unregSensors.length;
			for (const newSen of updatedUnregSensors) {
				const oldSen = unregSensors.find(sen => sen.sensorid === newSen.sensorid);
				if (oldSen) editObject(oldSen, newSen);
				else {
					unregSensors.push(newSen);
					const senI = props.sensors.findIndex(sen => sen.sensorid === newSen.sensorid);
					if (senI !== -1) props.sensors.splice(senI, 1);
				}
			}

			if (updatedSensors.length || previousUnregSenCount !== unregSensors.length) props.setSensors([...props.sensors]);
			if (newSensorIds.length) getSensorView({ variables: { filter: { sensorids: newSensorIds } } });
			setUnregSensors([...unregSensors]);
		},
	});
	const [addSensorGroup] = useMutation(ADD_SENSORGROUP, {
		onCompleted: ({ addSensorGroup }) => {
			props.setSensorGroups([...props.sensorGroups, addSensorGroup]);

			const queueI = sensorGroupQueue.findIndex(
				grp => grp.name === addSensorGroup?.name && grp.locationid === addSensorGroup?.locationid
			);
			if (queueI !== -1)
				setSensors({
					variables: {
						sensorids: sensorGroupQueue.splice(queueI, 1)[0]?.sensorIds,
						sensorgroupid: Number(addSensorGroup?.sensorgroupid),
					},
				});
		},
	});
	/* const [removeSensors] = useMutation(REMOVE_SENSORS, {
		onCompleted: ({ removeSensors }) => {
			Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorDeleted'), 'success');
			const regSens = removeSensors.filter(sen => sen.locationid).map(sen => sen.sensorid);
			const unregSens = removeSensors.filter(sen => !sen.locationid).map(sen => sen.sensorid);

			props.setSensors(props.sensors.filter(sen => !regSens.includes(String(sen.sensorid))));
			setUnregSensors(unregSensors.filter(sen => !unregSens.includes(String(sen.sensorid))));
		},
	}); */

	const [addSensor] = useMutation(ADD_SENSOR, {
		onCompleted: () => {
			console.log('');
		},
		onError: (error) => {
			console.log('Error adding sensor: ', error);
		}
	});

	function onSensorClick(sensor) {
		setSensorsUnderEdit([sensor]);
		setEditPopupEnabled(!editPopupEnabled);
	}
	function onEditButtonClick(sensorType) {
		if (sensorType === SENSOR_TYPE.registered) setSensorsUnderEdit(selectedSensors);
		else setSensorsUnderEdit(unregSelectedSensors);

		setEditPopupEnabled(!editPopupEnabled);
	}
	function onDeleteButtonClick(sensorType) {
		setShowConfirmDeletePopup(true);
		setSensoridsToDelete((sensorType === SENSOR_TYPE.registered ? selectedSensors : unregSelectedSensors).map(sen => sen.sensorid));
	}
	// Function to export selected sensor data to Excel
	function exportSelectedToExcel() {
		const selectedSensorIds = selectedSensors.map(sensor => sensor.sensorid);
		const selectedSensorData = sensorData.filter(sensor => selectedSensorIds.includes(sensor.sensorid));
		exportToExcel(selectedSensorData);
	}

	// Function to export data to an Excel file
	function exportToExcel(data) {
		const worksheet = XLSX.utils.json_to_sheet(data);

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sensor Data');

		const excelBuffer = XLSX.write(workbook, {
			bookType: 'xlsx',
			type: 'array',
		});
		const blob = new Blob([excelBuffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});

		const filename = 'selected_sensors_data.xlsx';
		saveAs(blob, filename);
	}

	// loading sensors
	useEffect(() => {
		if (props.sensors.length > 0) {
			setIsLoading(false);
		} else {
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, 2000); // stop loading after 2 seconds if there is 1 or no sensor

			return () => clearTimeout(timer);
		}
	}, [props.sensors]);



	const locationEditOpts = props.properties
		.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));
	const groupEditOpts = props.sensorGroups
		.map(grp => ({ value: grp.sensorgroupid, label: grp.name, locationid: grp.locationid }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));
	const propertyOpts = props.properties
		.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));
	const unregSenOpts = [...new Set(unregSensors.map(sen => sen.sensorref.split(' | ')[0]))]
		.map(opt => ({ value: opt, label: opt }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));

	const filteredSens = (
		selectedProperties.length
			? props.sensors.filter(sen => selectedProperties.some(pro => pro.value === sen.locationid))
			: props.sensors
	).sort((a, b) => (a.locationid < b.locationid || (a.locationid === b.locationid && a.name < b.name) ? -1 : 1));
	const filteredUnregSens = (
		unregSelectedProperties.length
			? unregSensors.filter(sen => unregSelectedProperties.some(pro => sen.sensorref.startsWith(pro.value)))
			: unregSensors
	).sort((a, b) => (a.sensorref < b.sensorref ? -1 : 1));

	const apIconProps = {
		style: { color: '#124', width: '1.1rem', height: '1.1rem', marginRight: '0.3rem' },
	};

	const { t } = useTranslation();

	return (
		<div style={{ margin: '1rem 0 0 1rem' }}>
			<div style={{ display: 'flex', margin: '0 1rem 1rem 0' }}>
				<SelectionTable
					localization={{
						title: t('sensorAdmin.registeredSensors'),
						nRowsSelected: t('sensorAdmin.numberOfSelectedSensors') + '{0}',
					}} // TODO: Find a better way to do this
					key={'registered' + String(refresh)}
					data={filteredSens}
					dataId='sensorid'
					onSelectionChange={sensors => setSelectedSensors(sensors)}
					//onRowClick={onSensorClick}
					columns={[
						{
							title: t('generic.name'),
							field: 'name',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.name}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setSensors({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												sensorids: [rowData.sensorid],
												name: e.target.value,
											},
										});
									}}
								/>
							),
						},

						{ title: t('generic.city'), field: 'city' },

						{ title: t('generic.address'), field: 'street' },

						/* 						{
													title: t('sensorAdmin.latestUpdated'),
													field: 'timestamp',
													customSort: (a, b) => {
														return new Date(a.timestamp) < new Date(b.timestamp) ? -1 : 1;
													},
												}, */
						//{ title: t('generic.id'), field: 'sensorref', maxLength: 200 },
						{
							title: t('generic.unit'),
							field: 'unit',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.unit}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setSensors({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												sensorids: [rowData.sensorid],
												unit: e.target.value,
											},
										});
									}}
								/>
							),
						},
					]}
					tableProps={{ maxColumnLength: 28,}}
				/>
				<SelectionSidebar
					buttons={[
						{
							label: t('genericAdmin.editSelected'),
							onClick: () => {
								onEditButtonClick(SENSOR_TYPE.registered);
								setEditPopupType(POPUP_TYPES.edit);
							},
							disabled: !selectedSensors.length,
						},

						{
							label: t('genericAdmin.deleteSelected'),
							onClick: () => {
								onEditButtonClick(SENSOR_TYPE.registered);
								setEditPopupType(POPUP_TYPES.delete);
							},
							disabled: !selectedSensors.length,
						},
						{
							label: t('genericAdmin.exportExcel'),
							onClick: () => exportSelectedToExcel(sensorData), // onDeleteButtonClick(SENSOR_TYPE.registered),
							//enabled: !selectedSensors.length,
							disabled: !selectedSensors.length,
						},
					]}
					filters={propertyOpts}
					updateSelectedFilters={properties => setSelectedProperties(properties || [])}
					localization={{ filterTitle: t('sensorAdmin.filterSensors'), filterPlaceholder: t('sensorAdmin.selectProperties') }}
					style={SIDEBAR_STYLE}
				/>
			</div>

			{(props.userInfo || {}).propertyAccess === PROP_SELECTION_TYPES.all.id && (
				<div style={{ display: 'flex', margin: '0 1rem 1.5rem 0' }}>
					<SelectionTable
						localization={{
							title: t('sensorAdmin.nonRegisteredSensors'),
							nRowsSelected: t('sensorAdmin.numberOfSelectedSensors') + '{0}', // TODO: Find a better way to do this
						}}
						data={filteredUnregSens}
						dataId='sensorid'
						onSelectionChange={sensors => setUnregSelectedSensors(sensors)}
						onRowClick={onSensorClick}
						columns={[
							{ title: t('generic.name'), field: 'name' },
							{ title: t('generic.city'), field: 'city' },
							{ title: t('generic.address'), field: 'street' },

							{
								title: t('sensorAdmin.latestUpdated'),
								field: 'timestamp',
								customSort: (a, b) => {
									return new Date(a.timestamp) < new Date(b.timestamp) ? -1 : 1;
								},
							},
							{ title: t('generic.id'), field: 'sensorref' },
						]}
						tableProps={{ maxColumnLength: 70 }}
					/>
					<SelectionSidebar
						buttons={[
							{
								label: t('genericAdmin.editSelected'),
								onClick: () => onEditButtonClick(SENSOR_TYPE.unregistered),
								disabled: !unregSelectedSensors.length,
							},

							{
								label: t('genericAdmin.deleteSelected'),
								onClick: () => onDeleteButtonClick(SENSOR_TYPE.unregistered),
								disabled: !unregSelectedSensors.length,
							},
						]}
						filters={unregSenOpts}
						updateSelectedFilters={properties => setUnregSelectedProperties(properties || [])}
						localization={{ filterTitle: t('sensorAdmin.filterSensors'), filterPlaceholder: t('sensorAdmin.selectKeyword') }}
						style={SIDEBAR_STYLE}
					/>
				</div>
			)}

			<EditPopup
				text={
					editPopupType === POPUP_TYPES.edit ?
						{
							title:
								sensorsUnderEdit.length === 1
									? t('sensorAdmin.editSensors') +
									(sensorsUnderEdit[0].locationid ? sensorsUnderEdit[0].name : sensorsUnderEdit[0].sensorref)
									: t('sensorAdmin.edit') + sensorsUnderEdit.length + t('sensorAdmin.selectedSensors'),
							subtitle:
								sensorsUnderEdit.length === 1
									? t('sensorAdmin.selectValuesNewSensorPromt_one')
									: t('sensorAdmin.selectValuesNewSensorPromt_any'),
							//t('sensorAdmin.selectValuesNewSensorPromt', { count: sensorsUnderEdit.length }),
						} : editPopupType === POPUP_TYPES.delete ?
							{
								title: t('sensorAdmin.confirmDeletion'),
								subtitle: t('sensorAdmin.confirmDeletePromt_any', { count: sensorsUnderEdit.length }),
								save: t('generic.confirm'),
							} : {}
				}

				fields={
					editPopupType === POPUP_TYPES.edit ?
						[
							{
								id: 'name',
								label: t('generic.name'),
								placeholder: sensorsUnderEdit.length === 1 && sensorsUnderEdit[0].name ? sensorsUnderEdit[0].name : '...',
								disabled: sensorsUnderEdit.length > 1,
							},
							{
								id: 'unit',
								label: t('generic.unit'),
								placeholder: sensorsUnderEdit.length === 1 && sensorsUnderEdit[0].unit ? sensorsUnderEdit[0].unit : '...',
							},
							{
								id: 'multiplier',
								label: t('generic.multiplier'),
								placeholder:
									sensorsUnderEdit.length === 1 && sensorsUnderEdit[0].multiplier ? sensorsUnderEdit[0].multiplier : '...',
							},
							/* 					{
													id: 'street',
													label: t('generic.address'),
													placeholder: sensorsUnderEdit.length === 1 && sensorsUnderEdit[0].street ? sensorsUnderEdit[0].street : '...',
													//disabled: sensorsUnderEdit.length > 1,
												},  */
							{
								id: 'alarmgraceminutes',
								label: t('sensorAdmin.alarmDelayMinutes'),
								placeholder:
									sensorsUnderEdit.length === 1 && sensorsUnderEdit[0].alarmgraceminutes
										? sensorsUnderEdit[0].alarmgraceminutes
										: '...',
							},
							{
								id: 'alarmperiods',
								label: t('sensorAdmin.alarmPeriods'),
								customRender: (
									<div style={{ display: 'flex' }}>
										{(() => {
											const allActivated = sensorsUnderEdit.every(sen =>
												props.alarmPeriodSensors.map(per => per.sensorid).includes(sen.sensorid)
											);
											return (
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														padding: '0.3rem 0.5rem',
														marginRight: '0.5rem',
														background: apCb ? '#afb' : allActivated ? '#eef' : '#eee',
														borderRadius: '5px',
													}}
												>
													{apCb ? <EditIcon {...apIconProps} /> : allActivated && <CheckIcon {...apIconProps} />}
													{apCb
														? t('sensorAdmin.unsavedChanges')
														: allActivated
															? t('sensorAdmin.activated')
															: sensorsUnderEdit.some(sen =>
																props.alarmPeriodSensors.map(per => per.sensorid).includes(sen.sensorid)
															)
																? t('sensorAdmin.activatedCertainSensors')
																: t('sensorAdmin.noneSpecified')}
												</div>
											);
										})()}
										<Button color='text' size='small' variant='outlined' onClick={() => setShowAlarmPeriodPopup(true)}>
											{t('generic.edit')}
										</Button>
									</div>
								),
							},
							{
								id: 'locationid',
								label: t('generic.property'),
								//label: `<span class="editable" contenteditable="true">${t('generic.property')}</span>`,
								options: locationEditOpts.concat({ value: null, label: t('sensorAdmin.noProperty') }),
								placeholder:
									sensorsUnderEdit.length === 1 && sensorsUnderEdit[0].city
										? sensorsUnderEdit[0].city + ': ' + sensorsUnderEdit[0].street
										: '...',
								onChange: locationid => setEditPopupSelectedPropertyId(locationid),
							},
							{
								id: 'sensorgroupid',
								label: t('sensorAdmin.group'),
								options: groupEditOpts
									.filter(grp => grp.locationid === editPopupSelectedPropertyId)
									.concat({ value: null, label: t('sensorAdmin.noGroup') }),
								placeholder:
									sensorsUnderEdit.length === 1 && sensorsUnderEdit[0].sensorgroupid
										? (
											groupEditOpts.find(
												grp =>
													grp.locationid === editPopupSelectedPropertyId &&
													Number(grp.value) === sensorsUnderEdit[0].sensorgroupid
											) || {
												label: '...',
											}
										).label
										: '...',
								disabled: !editPopupSelectedPropertyId || [...new Set(sensorsUnderEdit.map(sen => sen.locationid))].length !== 1,
								creatable: true,
							},
							{
								id: 'classification',
								label: t('generic.class'),
								options: Object.values(UNIT_CLASSIFICATIONS).map(classi => {
									return { value: classi.id, label: classi.label };
								}),
								placeholder:
									sensorsUnderEdit.length === 1
										? (
											Object.values(UNIT_CLASSIFICATIONS).find(
												classi => classi.id === sensorsUnderEdit[0].classification
											) || {
												label: '...',
											}
										).label
										: '...',
							},
						] : editPopupType === POPUP_TYPES.delete ?
							[] : []
				}
				isOpen={editPopupEnabled}
				canSave={apCb || undefined}
				onClose={() => {
					setEditPopupEnabled(!editPopupEnabled);
					setApCb();
				}}
				onSave={opts => {
					setEditPopupEnabled(false);
					//Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorInfo'), 'success');
					if (editPopupType === POPUP_TYPES.edit) {
						Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorInfo'), 'success');
					} else if (editPopupType === POPUP_TYPES.delete) {
						Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorDeleted'), 'success');
					}
					if (apCb) apCb();
					setApCb();

					if (opts.sensorgroupid && !props.sensorGroups.some(grp => grp.sensorgroupid === opts.sensorgroupid)) {
						const vars = { name: opts.sensorgroupid, locationid: opts.locationid || sensorsUnderEdit[0]?.locationid };
						addSensorGroup({
							variables: vars,
						});
						sensorGroupQueue.push({ ...vars, sensorIds: sensorsUnderEdit.map(sen => sen.sensorid) });
						delete opts.sensorgroupid;
					}
					const classification = UNIT_CLASSIFICATIONS[opts.classification];

					if (editPopupType === POPUP_TYPES.edit) {
						setSensors({
							variables: {
								sensorids: sensorsUnderEdit.map(sen => sen.sensorid && Number(sen.sensorid)),
								name: opts.name,
								unit: classification ? classification.unit : opts.unit,
								street: opts.street,
								multiplier: opts.multiplier && Number(opts.multiplier.toString().replace(',', '.')),
								alarmgraceminutes: opts.alarmgraceminutes && Number(opts.alarmgraceminutes),
								locationid: opts.locationid && Number(opts.locationid),
								sensorgroupid: opts.sensorgroupid && Number(opts.sensorgroupid),
								classification: opts.classification,
							},
						});
					} if (editPopupType === POPUP_TYPES.delete) {
						const date = new Date();
						const timestamp = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();

						sensorsUnderEdit.forEach(sensor => {
							setSensors({
								variables: {
									sensorids: [sensor.sensorid],
									sensorref: sensor.sensorref + '_locationid_' + sensor.locationid + '_removed_' + props.userInfo.username + timestamp,
									sensorgroupid: null,
									locationid: null,
								},
							});
						});
					}
					if (opts.classification === 'energyConsumption') {
						addSensor({
							variables: {
								sensorref: sensorsUnderEdit[0]?.sensorref + '_EC',
								//name: opts.name,
								//locationid: Number(opts.locationid),
							},
						});
					}
					setRefresh(refresh + 1);
				}}
			/>

			<DialogWrapper
				title={t('sensorAdmin.editAlarmPeriods')}
				width='30rem'
				dialogProps={{ open: showAlarmPeriodPopup, onClose: () => setShowAlarmPeriodPopup(false) }}
			>
				<AlarmPeriodEditor
					sensors={sensorsUnderEdit}
					onFinish={cb => {
						setShowAlarmPeriodPopup(false);
						setApCb(() => cb);
					}}
				/>
			</DialogWrapper>

			<ConfirmPopup
				enabled={showConfirmDeletePopup}
				title={t('sensorAdmin.confirmDeletion')}
				text={t('sensorAdmin.confirmDeletePromt', { count: sensoridsToDelete.length })}
				onFinish={suc => {
					const date = new Date();
					const timestamp = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
					if (suc)
						//removeSensors({
						setSensors({
							variables: {
								sensorids: sensoridsToDelete,
								sensorref: props.sensors.find(sen => sen.sensorid === sensoridsToDelete[0])?.sensorref + '_removed_' + props.userInfo.username + timestamp,
								sensorgroupid: null,
								locationid: null,
							},

						}).then(() => {
							Swal.fire(t('userAdmin.success'), t('sensorAdmin.sensorDeleted'), 'success');
						});

					setShowConfirmDeletePopup(false);
					setSensoridsToDelete([]);

				}}
			/>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loadingSensors')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</div>
	);
}

export default connect(getStateVariables(STORE.userInfo, STORE.sensors, STORE.sensorGroups, STORE.properties, STORE.alarmPeriodSensors), {
	setSensors,
	setSensorGroups,
})(SensorAdministration);
