import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField, Button } from '@material-ui/core';

import Select from './SelectWrapper';
import * as colors from '../colors';
import i18n from '../i18n';

const INPUT_TYPE = Object.freeze({ Int: 1, Float: 2 });

const DEFAULTS = Object.seal({ text: { placeholder: '...', cancel: i18n.t('generic.cancel'), save: i18n.t('generic.save') } }); // BUG: Object.freeze will stop translations from updating.

function getInitialEdits(fields) {
	const edits = {};
	for (const fie of fields || []) if (fie.default) edits[fie.id] = String(fie.default);
	return edits;
}

/**
 * Popup dialog that showing combined values of several sensors and allowing them to be edited
 * @param {Object} text : Different text strings for customizing the popup, {title, subtitle, error, cancel, save}
 * @param {Object[]} fields : The edit fields and their properties, [{id: String, label: String, placeholder: String, default: String, options: {value, label}[], type: INPUT_TYPES, elementType: String (HTML5 input type), creatable: Boolean, required: Boolean, disabled: Boolean, customRender: HTML/JSX, multiline: Boolean, onChange: (any) => void}, ...]
 * @param {Boolean} isOpen : Whether or not the popup should show
 * @param {Boolean} canSave : Override the default check and force allow/forbid saving
 * @param {Function} onClose : Called when the dialog wants to close
 * @param {Function} onSave : Called when the save button has been pressed with the edited values
 * @param {Object} dialogStyle : CSS applied to the popup-dialog
 * @param {Boolean} confirmClose : Whether or not to show a confirmation dialog when the user tries to close the popup
 */
function EditPopup(props) {
	const text = props.text || {},
		fields = props.fields || [],
		isOpen = Boolean(props.isOpen),
		onClose = props.onClose || (() => null),
		onSave = props.onSave || (() => null),
		confirmClose = Boolean(props.confirmClose) || false; //if the default is to show a confirmation dialog when the user tries to close the popup or not

	const [edits, _setEdits] = useState(getInitialEdits(props.fields)); // Stores all edits made to input fields
	const [_isOpen, setIsOpen] = useState(false); // Whether or not the popup is open
	const [isOpenConfirm, setIsOpenConfirm] = useState(false); // Whether or not the confirmation dialog is open

	// Remove saved edits whenever popup closes
	useEffect(() => {
		if (!_isOpen) _setEdits(getInitialEdits(props.fields));
	}, [_isOpen, props.fields]);

	// reacts when isOpen changes
	useEffect(() => {
		if (confirmClose && _isOpen === true) {
			setIsOpenConfirm(true);
		} else {
			setIsOpen(isOpen);
		}
	}, [isOpen]);

	function setEdits(newEdits) {
		for (const [key, val] of Object.entries(newEdits)) {
			const onChange = fields.find(fie => fie.id === key)?.onChange;
			if (typeof onChange === 'function' && val !== edits[key]) onChange(val);
		}

		_setEdits(newEdits);
	}

	// Checks if the input is of the correct type and returns true (error) if it is not
	function checkInputError(type, input) {
		if (type === INPUT_TYPE.Int) {
			if (isNaN(input) || input.includes('.')) return true;
		} else if (type === INPUT_TYPE.Float) {
			if (isNaN(input)) return true;
		}
		return false;
	}

	return (
		<Dialog
			open={_isOpen}
			onClose={onClose}
			classes={makeStyles({ paper: { minWidth: '18rem', maxWidth: '28rem', overflow: 'visible', ...props.dialogStyle } })()}
		>
			<DialogTitle>{text.title}</DialogTitle>

			<DialogContent style={{
				 padding: '0 1.5rem',
				 overflowY: 'auto',
				 overflow: 'visible',
				}}>
				<DialogContentText component='div' style={{ marginTop: '-0.1rem' }}>
					{text.subtitle}
				</DialogContentText>

				{fields.map(
					fie =>
						!fie.disabled && (
							<div key={fie.id} style={{ margin: '0 0 0.5rem 0' }}>
								<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem' }}>{fie.label}</div>
								{fie.customRender ||
									(fie.options ? (
										<Select
											isCreatable={fie.creatable}
											selectProps={{
												[fie.placeholder && 'placeholder']: fie.placeholder,
												options: fie.options,
												onChange: selection =>
													setEdits({ ...edits, [fie.id]: selection ? selection.value : undefined }),
												maxMenuHeight: 204,
											}}
										/>
									) : (
										<TextField
											type={fie.elementType || 'text'}
											margin='dense'
											variant='outlined'
											placeholder={String(fie.placeholder || DEFAULTS.text.placeholder)} // String() required to prevent errors
											defaultValue={fie.default && String(fie.default)}
											error={fie.type && edits[fie.id] ? checkInputError(fie.type, edits[fie.id]) : false}
											fullWidth
											multiline={fie.multiline}
											rows={2}
											rowsMax={15}
											onChange={e => setEdits({ ...edits, [fie.id]: e.target.value })}
											inputProps={{ style: { fontSize: '86%' } }}
											style={{ margin: '0' }}
										/>
									))}
								{fie.required &&
									!fie.disabled &&
									!(edits[fie.id] === undefined || edits[fie.id] === '') &&
									fie.required_rule &&
									fie.required_rule(edits[fie.id]) && (
										<pre style={{ color: '#f00', margin: '0 0 0.2rem 0.1rem', fontSize: '80%' }}>
											{fie.required_rule_text}
										</pre>
									)}
							</div>
						)
				)}
			</DialogContent>

			{text.error && (
				<h4 style={{ color: '#f00', margin: '0.5rem auto 0 auto', fontSize: '115%', fontWeight: '450' }}>{text.error}</h4>
			)}

			<DialogActions>
				<Button onClick={onClose} style={{color:colors.text}}>
					{text.cancel || DEFAULTS.text.cancel}
				</Button>
				<Button
					disabled={fields.some(
						fie =>
							fie.required &&
							!fie.disabled &&
							(edits[fie.id] === undefined || edits[fie.id] === '' || (fie.required_rule && fie.required_rule(edits[fie.id])))
					)}
					onClick={() => onSave(edits)}
					style={{color:colors.text}}
				>
					{text.save || DEFAULTS.text.save}
				</Button>
			</DialogActions>

			<Dialog
				open={isOpenConfirm}
				onClose={() => setIsOpenConfirm(false)}
				classes={makeStyles({ paper: { minWidth: '18rem', maxWidth: '28rem', overflow: 'visible', ...props.dialogStyle } })()}
			>
				<DialogTitle>{i18n.t('confirmPopup.confirmPrompt')}</DialogTitle>

				<DialogContent style={{ padding: '0 1.5rem', overflow: 'visible' }}>
					<DialogContentText component='div' style={{ marginTop: '-0.5rem' }}>
						{i18n.t('confirmPopup.confirmsubtext')}
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => setIsOpenConfirm(false)} style={{color:colors.text}}>
						{DEFAULTS.text.cancel}
					</Button>
					<Button
						onClick={() => {
							setIsOpenConfirm(false);
							setIsOpen(false);
						}}
						style={{color:colors.text}}
					>
						{i18n.t('generic.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</Dialog>
	);
}

export default EditPopup
export { INPUT_TYPE};
